import React from "react";
import { createStyles, makeStyles, Box } from '@material-ui/core';
import { ExtendedTheme, Heading, TextBlock, Link } from "neon-design-system";
import { DownloadAppContentProps } from './DownloadAppContentInterface';

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
  layout: {
    maxWidth: theme.spacing(87.5),
    margin: '0 auto',
  },
  content: {
    flexGrow: 1,
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.themeColors.grey[800],
    width: theme.spacing(20),
    height: theme.spacing(20),
    borderRadius: theme.spacing(3),
    flexDirection: 'column',
  },
  infoWrapper: {
    textAlign: 'center',
  },
  requiredSoftware: {
    backgroundColor: theme.themeColors.grey[800],
    borderRadius: theme.spacing(0.5),
    maxWidth: theme.spacing(87.5),
    padding: theme.spacing(2),
    margin: '0 auto'
  }
}));

const DownloadAppContent = (props: DownloadAppContentProps) => {
  const classes = useStyles(props);
  const { logo, logoTitle, title, description, content, versionDescription } = props;

  return (
    <div className={classes.layout}>
      <div className={classes.logoWrapper}>
        <div className={classes.logo}>
          {logo}
          <Heading styleName="title1">{logoTitle}</Heading>
        </div>
      </div>
      <div className={classes.infoWrapper}>
        <Box mb={1}>
          <Heading styleName="largeTitle">{title}</Heading>
        </Box>
        <Box mb={4}>
          <TextBlock styleName="largeBody2" ink="muted">{description}</TextBlock>
        </Box>
        {content}
        {
          versionDescription && (
            <Box mb={8}>
              <TextBlock styleName="caption1" ink="muted">{versionDescription}</TextBlock>
            </Box>
          )
        }
        <div className={classes.requiredSoftware}>
            If you are not running Windows 10, please <Link to="https://www.microsoft.com/en-us/download/details.aspx?id=48130" target="_blank" ignoreRouter>download and install</Link> Microsoft .NET Framework 4.6
        </div>
      </div>
    </div>
  );
};

export default DownloadAppContent;
