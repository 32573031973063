import gql from 'graphql-tag';
import { userInfoFields } from './partials';

export const GET_MY_USER = gql`
	query myUser{
		myUser {
			${userInfoFields}
			tier
			marketDataRebate
			rebate
			accountNumber
			isInternal
			isBasic
			broker
		}
	}
`;

export const GET_MY_USER_CONCIERGE_PERMISSIONS = gql`
	query myUser{
		myUser {
			accountChangeRequestEnabled
			accountOpeningFormEnabled
		}
	}
`;

export type Role = 'USER' | 'USER_RO';

export enum ClientStatus {
	NONCLIENT = 'NONCLIENT',
	ONBOARDING = 'ONBOARDING',
	CLIENT = 'CLIENT'
}

export const parseClientStatus = (obj: unknown): ClientStatus | undefined => {
	if (typeof obj !== 'string') return undefined;
	if (!Object.values<string>(ClientStatus).includes(obj)) return undefined;
	return obj as ClientStatus;
}

export interface RegistrationSurvey {
	clientStatus?: ClientStatus;
	marexContact?: string;
}

export interface Interest {
	application: string;
	requested: Date;
}

export enum Tiers {
	STANDARD = 'STANDARD',
	BASIC = 'BASIC',
}

export enum RebateFlag {
	Y = 'Y',
	N = 'N',
    B = 'B'
}

export interface MyUser {
	auth0email: string;
	firstName: string;
	lastName: string;
	company: string;
	phoneNumber: string;
	country?: string;
	registrationSurvey?: RegistrationSurvey
	categories: string[];
	acceptedTerms?: boolean;
	acceptedPrivacy?: boolean;
	interest?: Interest[];
}

export interface MyUserResponse {
	neonUserId: string;
	firstName: string | null;
	lastName: string | null;
	company: string | null;
	phoneNumber: string | null;
	country: string;
	categories: string[] | null;
	acceptedTerms: boolean | null;
	acceptedPrivacy: boolean | null;
	interest?: Interest[];
	accountNumber: string;
}

export interface MyUserResponseWithTier extends MyUserResponse{
	tier: Tiers;
	marketDataRebate: RebateFlag;
	rebate: RebateFlag;
}

export interface MyUserResponseWithPackageInfo extends MyUserResponseWithTier {
	isBasic: boolean;
	isInternal: boolean;
	broker: boolean;
}

export interface MyUserQueryResult {
  myUser: MyUserResponse;
}

export default GET_MY_USER;
