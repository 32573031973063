import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Link, ExtendedTheme, ApplicationLogo as NDSApplicationLogo} from 'neon-design-system';

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
	divLogo: {
		cursor: 'pointer',
		textDecoration: 'none',
	},
	logo: {
		justifyContent: 'center',

		'@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
			'& > svg': {
				width: theme.spacing(12.5),
			}
		}
	},
  }));

interface ApplicationLogoProps {
	className?: string;
	link?: string;
	logoOnly?: boolean;
	logoClass?: string;
}

const ApplicationLogo = (props: ApplicationLogoProps) => {
	const classes = useStyles();
	const { className, logoClass, link = '/', logoOnly = false } = props;

	const logo = (
		<div className={[classes.divLogo, className].join(' ')}>
			<NDSApplicationLogo app="Neon" className={[classes.logo, logoClass].join(' ')} />
		</div>
	);

	return logoOnly ? logo : (
		<Link to={link}>{logo}</Link>
	);
};

export default (ApplicationLogo);
