import React from 'react';
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { ExtendedTheme, Heading, Button, TextBlock } from "neon-design-system";

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
  item: {
    borderRadius: theme.spacing(0.5),
    background: theme.palette.grey[800],
    padding: theme.spacing(3),
  }
}));

interface DownloadAppItemProps {
  name: string;
  version?: string;
  link: string;
  isBeta?: boolean
}

export const DownloadAppItem = (props: DownloadAppItemProps) => {
  const classes = useStyles();
  const { name, version, link, isBeta } = props;

  return (
    <div className={classes.item}>
      <Heading styleName="headline1">{name}</Heading>
      {
        version ? (
          <Box mt={0.5} mb={2}>
            <TextBlock styleName="caption1" ink="muted">Version {version}</TextBlock>
          </Box>
        ) : (
          <Box mt={4.5} />
        )
      }
      <Button
        colorType={isBeta ? "grey" : "primary"}
        href={link}
        size="large"
      >
        {isBeta ? 'Download beta' : 'Download app'}
      </Button>
    </div>
  );
};

export default DownloadAppItem;