import React from 'react';
import { Container, Heading, Tabs, TextBlock, ExtendedTheme, Loader } from 'neon-design-system';
import { useHistory, useRouteMatch, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { Security } from './Security';
import { Profile } from './Profile';
import { Privacy } from './Privacy';
import { NeonPackage } from './NeonPackage';
import { useQuery } from "@apollo/client";
import GET_MY_USER, { MyUserResponseWithPackageInfo } from "../../graphQL/queries/myUser";

const useStyles = makeStyles((theme: ExtendedTheme) => ({
  titleOffset: {
    marginBottom: theme.spacing(1),
  },
  root: {
    flexGrow: 0,
  },
}));

const ManageAccount = () => {
  const { data, loading } = useQuery<{ myUser: MyUserResponseWithPackageInfo }>(GET_MY_USER, {fetchPolicy: 'network-only'});

  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const classes = useStyles();

  if (loading) {
    return <Loader />
  }

  const pages = {
    profile: `${path}/profile`,
    security: `${path}/security`,
    privacy: `${path}/privacy`,
    package: `${path}/package`,
  };
  const currentPage = location.pathname.split('/').pop();

  const tabs = [
    {title: 'Profile', value: 'profile'},
    {title: 'Security', value: 'security'},
    {title: 'Privacy Control Centre', value: 'privacy'},
  ];

  return (
    <Container className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Heading xs="small" styleName="largeTitle" className={classes.titleOffset}>Manage your account</Heading>
          <TextBlock ink="muted">Keep your account safe and up-to-date by managing your details</TextBlock>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            tabs={tabs}
            onChange={page => history.push(pages[page as 'profile' | 'security' | 'privacy' | 'package'])}
            value={currentPage}
          />
        </Grid>
        <Switch>
          <Route exact path={pages.profile} component={Profile}/>
          <Route exact path={pages.security} component={Security}/>
          <Route exact path={pages.privacy} component={Privacy}/>
          <Redirect to={pages.profile}/>
        </Switch>
      </Grid>
    </Container>
  );
};

export default ManageAccount;