import gql from 'graphql-tag';

const UPDATE_PASSWORD = gql`
	mutation UpdatePassword($input: ChangePasswordInput!) {
        updatePassword(input: $input) {
            success
        }
	}
`;

export interface UpdatePasswordRequest {
    input: {
        oldPassword: string;
        newPassword: string;
    }
}

export interface UpdatePasswordResult {
    updatePassword: {
        success: boolean;
    }
}

export default UPDATE_PASSWORD;