import React from 'react';
import { config } from '../../config';
import App from '../App';
import { AuthProvider, AuthCheckLogin, AuthDataProvider, useAuth0, AuthLogAppInfo, AuthLogEntry } from 'neon-auth0';
import { Loader } from 'neon-design-system';
import SplashScreen from '../../pages/SplashScreen';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { baseUrls } from "../../helpers/urls";
import Signup from '../../pages/Signup/Signup';
import ApolloAuth0Client from '../../apollo/ApolloAuth0Client';
import { Forgot } from '../../pages/ForgotPassword';
import PasswordUpdated from '../../pages/PasswordUpdated';
import CheckCompleteProfile from "../CheckCompleteProfile";
import EmailVerification from '../EmailVerification';
import { getSetReturnUrl, getReturnUrl, clearReturnUrlFromStorage } from '../../helpers/returnUrl';
import { getStaticRoutes } from '../../helpers/staticRoutes';
import Error from '../Error';
import { Login } from '../../pages/Login';
import { OneTrustLogin } from '../OneTrustLogin';
import { InterceptAuth0Callback2 } from './InterceptAuth0Callback2';
import * as log from '../../helpers/logger';
import { useSessionData } from '../../contexts/SessionDataProvider';
import { traderDownloadScopes } from '../../helpers/auth0';
import { clearSessionId } from 'neon-utils';
import { getUrlParams } from '../../helpers/common';

const UNVERIFIED_EMAIL_ERROR = 'Access Denied: User has not responded to verification email.';

const onRedirectCallback = (appState: any) => {
	const url = getReturnUrl();
	clearSessionId();

	if (url) {
		clearReturnUrlFromStorage();
		window.location.replace(url);
	} else {
		window.history.replaceState(
			{},
			document.title,
			appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
		);
	}
};

export const InsightsScopes = " admin:insights insights:content-edit insights:content-publish insights:content-reader insights:content-reader-trial";
export const connectLocationScopes = ` ${traderDownloadScopes.join(' ')} connect:download-uk-risk`;
export const internalApplicationsScopes = " csc nominations recs";
export const integralPreciousScope = " integral:precious";
export const integralFXScope = " integral:fx";

const AuthErrorHandler = ({ user, error, message }: { user?: any, error?: Error, message: string }) => {
	let newErrorMessage: string = ''
	const location = useLocation();
	const callbackError = getUrlParams(location.search, 'error');
	const callbackDescription = getUrlParams(location.search, 'error_description');
	const { logout } = useAuth0();

	if (callbackError === 'access_denied' && callbackDescription?.includes('LOGIN_FROM_LOCATION_BLOCKED')) {
		const splitMessage = callbackDescription?.split('_');
		const country = splitMessage[splitMessage.length - 1];
		newErrorMessage = `Login from ${country} is not allowed.`;
	}

	const defaultHeadline = 'Please try again later.';
	const primaryActionProps = {
		label: 'Sign out',
		onClick: () => { logout({ returnTo: window.location.origin }) },
	};
	log.error('Error occured while logging on to dashboard', { message: message, user: user, error: error });

	if (newErrorMessage) {
		return <Error heading={newErrorMessage} disableDescription />
	}

	if (message === UNVERIFIED_EMAIL_ERROR) {
		return <Signup unactivatedAccount />
	}

	return (message.length > defaultHeadline.length
		? <Error heading={defaultHeadline} headline={message} primaryAction={primaryActionProps} />
		: <Error heading={message} primaryAction={primaryActionProps} />
	);
}

const Auth0RedirectLogon = () => {
	const appInfo: AuthLogAppInfo = useSessionData();
	getSetReturnUrl();

	if (window.location.search.indexOf('code=success') > -1) {
		window.location.replace(window.location.href.replace('code=success', ''));
	}

	const mfaScope: string = window.location.pathname === baseUrls.loginMfa ? ' mfa' : '';

	return (
		<AuthProvider
			domain={config.auth.domain}
			clientId={config.auth.clientId}
			audience={config.auth.audience}
			redirectUri={config.auth.redirectUri}
			scope={"openid profile email crude energy metals connect scrap portfolio trader onboarding carbon" + integralPreciousScope + integralFXScope + internalApplicationsScopes + InsightsScopes + connectLocationScopes + mfaScope}
			onRedirectCallback={onRedirectCallback}
		>
			<InterceptAuth0Callback2>
				<AuthCheckLogin
					loader={<Loader />}
					errorComponentCallback={AuthErrorHandler}
					appInfo={appInfo}
					addLogEntry={(authlogEntry: AuthLogEntry) => log.info('User authenticated with Neon', authlogEntry)}
					unAuthenticated={(<Switch>
						<Route path={baseUrls.splashScreen} exact={true} component={SplashScreen} />
						{config.oneTrust.enableLogin && <Route path={baseUrls.oneTrustLogin} exact={true} component={OneTrustLogin} />}
						{
							config.isSignUpEnabled && (
								<Route path={baseUrls.signup} exact={true} component={Signup} />
							)
						}
						{getStaticRoutes(true)}
						<Route path={baseUrls.forgot} exact={true} component={Forgot} />
						<Route path={baseUrls.emailverification} component={EmailVerification} />
						<Route path={baseUrls.passwordUpdated} component={PasswordUpdated} />
						<Route path={baseUrls.error} component={Error} />
						<Route path={baseUrls.login} component={Login} />
						<Route path={baseUrls.loginMfa} component={Login} />
						<Redirect to={baseUrls.splashScreen} />
					</Switch>)}
				>
					<AuthDataProvider>
						<ApolloAuth0Client>
							<CheckCompleteProfile>
								<App />
							</CheckCompleteProfile>
						</ApolloAuth0Client>
					</AuthDataProvider>
				</AuthCheckLogin>
			</InterceptAuth0Callback2>
		</AuthProvider>
	);
}

export default Auth0RedirectLogon;
