import { ClientStatus, MyUser, MyUserResponse } from "../graphQL/queries/myUser";
import { decamelize } from "./common";
import { ProfileInfoErrors } from "../models";

export function validatePersonalInfo(myUser: MyUser, { validateRegistrationSurvey = true } = {}): ProfileInfoErrors {
    const regex = new RegExp(/[A-Za-z0-9].*[A-Za-z0-9]/);
    const errors: ProfileInfoErrors = {};
    (['firstName', 'lastName', 'company', 'phoneNumber', 'country'] as (keyof MyUser)[]).forEach((field: keyof MyUser) => {
      if(
          !myUser[field] ||
          (field !== 'phoneNumber' && !regex.test(myUser[field] as (keyof MyUser)))
      ) {
        errors[field] = `Please ${field === 'country' ? 'select' : 'enter'} your ${decamelize(field, ' ')}`;
      }
    });

  if (validateRegistrationSurvey) {
    const atLeast4Letters = /[A-Za-z].*[A-Za-z].*[A-Za-z].*[A-Za-z]/;
    const clientStatus = myUser.registrationSurvey?.clientStatus;
    if (!clientStatus) {
      errors.clientStatus = 'Please select an option';
    }
    if (
      (clientStatus === ClientStatus.CLIENT || clientStatus === ClientStatus.ONBOARDING) &&
      !atLeast4Letters.test(myUser.registrationSurvey?.marexContact || '')
    ) {
      errors.marexContact = 'Please enter your Marex contact';
    }
  }
  return errors;
};

export function partialInfoFromResponse(response: MyUserResponse): Partial<MyUser> {
  delete response.neonUserId;
  const keys = Object.keys(response) as (keyof MyUserResponse)[];
  const notEmptyFields = keys.filter(k => response[k] !== null);
  const partialData: Partial<MyUser> = notEmptyFields.reduce((userPartial, key) => ({...userPartial, [key]: response[key]}), {});
  return partialData;
}

export const mapToUpdateMyUserInput = (user: MyUser) => {
  const newUser = { ...user };
  delete newUser['auth0email'];
  delete newUser['interest'];
  if (user.registrationSurvey?.clientStatus === ClientStatus.NONCLIENT) {
    delete newUser.registrationSurvey?.marexContact;
  }
  return newUser;
};
