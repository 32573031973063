import React from 'react';
import { ExtendedTheme, TextBlock, UserInterfaceCheckCircleFill } from "neon-design-system";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
    icon: {
        color: theme.themeColors.grey[500],
        transition: 'color 250ms ease-out',
    },
    iconChecked: {
        transition: 'color 250ms ease-in',
        color: theme.themeColors.common.white,
    },
    passwordStrengthWrapper: {
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing(0.5),
            marginBottom: theme.spacing(1)
        }
    },
    passwordStrengthMessage: {
        marginLeft: theme.spacing(1)
    }
}));

interface PasswordStrengthItem {
    checked: boolean;
    message: string;
}

interface PasswordStrengthProps {
    items: PasswordStrengthItem[];
    className?: string;
}

export const PasswordStrength = (props: PasswordStrengthProps) => {
    const { items, className } = props;
    const classes = useStyles();

    return (
      <div className={[classes.passwordStrengthWrapper, className].filter((n) => !!n).join(' ')}>
          {
              items.map((item, key) => (
                <div key={key}>
                    <UserInterfaceCheckCircleFill
                      className={[classes.icon, item.checked && classes.iconChecked].filter((n) => !!n).join(' ')}
                    />
                    <TextBlock className={classes.passwordStrengthMessage} styleName="caption1">{item.message}</TextBlock>
                </div>
              ))
          }
      </div>
    );
};

export default PasswordStrength;