import React from 'react';
import { ExtendedTheme, MarexSpectronLogo, TextBlock } from 'neon-design-system';
import { makeStyles, createStyles  } from '@material-ui/styles';
import PolicyLinks from './PolicyLinks';
import CopyrightText from './CopyrightText';
import { containerWidth, largeContainerWidth } from '../../../../constants/splash';

interface SplashScreenFooterProps {
    className?: string;
}

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
    footer: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bannerContainer: {
        width: '100%',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
            maxWidth: containerWidth,
            marginTop: theme.spacing(8),
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(6),
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(8),
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: largeContainerWidth,
        }
    },
    bannerPanel: {
        backgroundColor: theme.themeColors.grey[800],
        borderRadius: theme.spacing(1),
        padding: theme.spacing(4, 2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
        },
    },
    logo: {
        width: '100px',
        height: 'initial',
    },
}));

const SplashScreenFooter = (props: SplashScreenFooterProps) => {
    const classes = useStyles();
    const { className } = props;

    return (
        <div className={[classes.footer, className].join(' ')}>
            <div className={classes.bannerContainer}>
                <div className={classes.bannerPanel}>
                    <MarexSpectronLogo className={classes.logo} />
                    <TextBlock xs="small" sm="medium" xl="large" styleName="body" ink="muted">
                        Marex is a tech-enabled liquidity hub connecting clients to global energy, commodity, and financial markets.
                    </TextBlock>
                </div>
            </div>
            <CopyrightText />
            <PolicyLinks type="signin" responsive />
        </div>
    );
};

export default SplashScreenFooter;
