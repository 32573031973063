import React, { useState, ChangeEvent, useEffect, Fragment } from 'react';
import { TextBlock, TextField, Button, Snackbar, UserInterfaceCheckCircleFill, ExtendedTheme } from 'neon-design-system';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import UPDATE_PASSWORD, { UpdatePasswordResult, UpdatePasswordRequest } from '../../graphQL/mutations/updatePassword';
import { useAuth0 } from 'neon-auth0';
import PasswordStrength from "../../components/PasswordStrength/PasswordStrength";
import { config } from '../../config';

const securityStyles = makeStyles((theme: ExtendedTheme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  passwordStrength: {
    marginTop: theme.spacing(0.5),
  },
  formWrapper: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial'
    },
  }
}));

interface Errors {
  current?: string;
  new?: string;
  confirm?: string;
}

export const Security = () => {
  const classes = securityStyles();
  const auth0 = useAuth0();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [errors, setErrors] = useState<Errors>({});
  const [submitted, setSubmitted] = useState(false);
  const [doUpdatePassword, {data: updateResult, loading: updateLoading}] = useMutation<UpdatePasswordResult, UpdatePasswordRequest>(UPDATE_PASSWORD, {
    onError: e => {
      if(e.networkError) {
        setError(e.toString().replace('Error: Network error: ', ''));
        return
      }
      if(e.graphQLErrors && e.graphQLErrors.length) {
        const first = e.graphQLErrors[0];
        setError(first.message)
        return;
      }
      setError(e.toString());
    }
  });
  const [form, setForm] = useState({
    current: '',
    new: '',
    confirm: '',
  });
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    aAd: false,
    special: false,
  });

  const passwordStrengthItems = [
    {
      message: '8 characters minimum',
      checked: passwordStrength.length,
    },
    {
      message: 'Lower case (a–z), upper case (A–Z) and numbers (0–9)',
      checked: passwordStrength.aAd,
    },
    {
      message: 'Special characters (!@#$%^&*)',
      checked: passwordStrength.special,
    }
  ];

  useEffect(() => {
    if(updateResult && updateResult.updatePassword.success) {
      setSuccess('Password updated. Relogin …');
      setTimeout(() => auth0.loginWithRedirect({redirectUri: config.auth.redirectUri}), 1000);
    }
  }, [updateResult]);

  useEffect(() => {
    if (submitted) {
      validate();
    }
  }, [submitted, form]);

  const fieldChange = (field: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [field]: e.target.value,
    });
    if(field === 'new') {
      checkPasswordStrength(e.target.value);
    }
  }

  const validate = () => {
    const newErrors: Errors = {};
    if(form.new === form.current) {
      newErrors.new = 'New password can\'t be the same';
    }
    if(!form.current) {
      newErrors.current = 'Please enter your current password';
    }
    if(!passwordStrength.aAd || !passwordStrength.length || !passwordStrength.special){
      newErrors.new = 'Password is not strong enough';
    }
    if(!form.new) {
      newErrors.new = 'Please enter a password';
    }
    if(form.new !== form.confirm) {
      newErrors.confirm = 'Passwords do not match';
    }
    setErrors(newErrors);
    return JSON.stringify(newErrors) === '{}';
  }

  const checkPasswordStrength = (password: string) => {
    const strength = {...passwordStrength};
    const aAd = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+/; // one lower, one upper, one number
    const special = /[!@#$%^&*()]+/;
    strength.length = password.length >= 8;
    strength.aAd = aAd.test(password);
    strength.special = special.test(password);
    setPasswordStrength(strength);
  }

  const updatePassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSubmitted(true);
    if(!validate()) {
      return;
    }
    doUpdatePassword({
      variables: {
        input: {
          oldPassword: form.current,
          newPassword: form.new,
        }
      }
    });
  }

  if (auth0.user?.email?.toLowerCase().includes('@marexspectron.com') || auth0.user?.email?.toLowerCase().includes('@marex.com')) {
    return (
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <TextBlock>To change your Marex user account password, please contact your IT Admin.</TextBlock>
        </Grid>
      </Grid>
    );
  }

  return (<Fragment>
    <Snackbar
      open={!!error}
      message={error}
      type="error"
      onClose={() => setError('')}
      autoHideDuration={3000}
    />
    <Snackbar
      open={!!success}
      message={success}
      type="success"
      onClose={() => setSuccess('')}
      autoHideDuration={3000}
    />
    <form className={classes.form}>
      <Grid item xs={12} sm={6} lg={4} container spacing={2} className={classes.formWrapper}>
        <Grid item xs={12}>
          <TextField
            onChange={fieldChange('current')}
            value={form.current}
            label="Current password"
            type="password"
            error={!!errors.current}
            errorText={errors.current}
            size="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                onChange={fieldChange('new')}
                value={form.new}
                label="New password"
                type="password"
                error={!!errors.new}
                errorText={errors.new}
                size="large"
              />
            </Grid>
            <PasswordStrength className={classes.passwordStrength} items={passwordStrengthItems} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={fieldChange('confirm')}
            value={form.confirm}
            label="Re-enter new password"
            type="password"
            error={!!errors.confirm}
            errorText={errors.confirm}
            size="large"
          />
        </Grid>
        <Grid item xs={12} className={classes.submitButton}>
          <Button size="large" type="submit" colorType="primary" onClick={updatePassword} busy={updateLoading}>Update details</Button>
        </Grid>
      </Grid>
    </form>
  </Fragment>);
};

export default Security;
