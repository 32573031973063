import { isNullOrUndefined } from 'util';
import { IdToken } from '@auth0/auth0-react';
import { config } from '../config';

export const hasPassportPermissions = (idToken: IdToken): boolean => {
	return !isNullOrUndefined(getCustomClaimFromIdToken(idToken, 'xtpGroup'));
}

export const getCustomClaimFromIdToken = (idToken: IdToken, key: string): string => {
	return idToken[`https://${config.auth.domain}/${key}`];
};

export const traderDownloadScopes = [
	'connect:download-uk',
	'connect:download-us',
	'connect:download-us-beta',
	'connect:download-shanghai',
	'connect:download-uk-trainer'
];

export const isNeonTraderDownloadEnabled = (scopes: string[]) => scopes.includes('connect') && traderDownloadScopes.some(traderScope => scopes.includes(traderScope));

export const isAllNeonTraderAppsEnabled = (scopes: string[]) => traderDownloadScopes.every((scope:string) => scopes.includes(scope));

export const userDownloadScopes = (scopes: string[]) => { return scopes.filter(scope => traderDownloadScopes.includes(scope)); }