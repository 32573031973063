import React, { useState, ChangeEvent, useEffect, Fragment } from 'react';
import {
  Heading,
  TextField,
  Button,
  ExtendedTheme,
  Checkbox,
  Loader,
  Snackbar,
  Select,
  Option
} from 'neon-design-system';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_MY_USER, {
  UpdateMyUserResult,
  UpdateMyUserRequest
} from '../../graphQL/mutations/updateMyUser';
import { MyUser } from '../../graphQL/queries/myUser';
import GET_MY_USER_WITH_CATEGORIES, { MyUserWithCategoriesQueryResult as MyUserWithCategoriesQueryResult } from '../../graphQL/queries/myUserWithCategories';
import { ProfileInfoErrors } from '../../models';
import { validatePersonalInfo, partialInfoFromResponse, mapToUpdateMyUserInput } from '../../helpers/profileInfo';
import { countriesOptions, isSanctioned } from '../../helpers/countries';

const profileStyles = makeStyles((theme: ExtendedTheme) => ({
  spacer: {
    marginBottom: theme.spacing(2),
  },
  form: {
    width: '100%',
    padding: theme.spacing(2),
  },
  formWrapper: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial'
    },
  }
}));

export const Profile = () => {
  const classes = profileStyles();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [infoErrors, setInfoErrors] = useState<ProfileInfoErrors>({});
  const [myUser, setMyUser] = useState<MyUser>({
    auth0email: '',
    firstName: '',
    lastName: '',
    company: '',
    phoneNumber: '',
    country: '',
    categories: []
  });
  const [categories, setCategories] = useState<string[]>([]);
  const [profileError, setProfileError] = useState('');
  const [success, setSuccess] = useState('');
  const {data, loading} = useQuery<MyUserWithCategoriesQueryResult>(GET_MY_USER_WITH_CATEGORIES, {fetchPolicy: 'network-only'});
  const [doUpdateMyUser, {data: updateResult, loading: updateLoading}] = useMutation<UpdateMyUserResult, UpdateMyUserRequest>(UPDATE_MY_USER, {
    onError: e => {
      setProfileError(e.toString());
    }
  });

  const currentCountry = countriesOptions.find(c => c.value === myUser.country);

  useEffect(() => {
    let availableCategories: string[] = [];
    if(data && data.categories) {
      availableCategories = data.categories.map(category => category.name);
      setCategories(availableCategories);
    }
    if(data && data.myUser) {
      const myUserDetails = partialInfoFromResponse(data.myUser);
      setMyUser({
        ...myUser,
        ...myUserDetails,
      });
    }
  }, [data]);

  useEffect(() => {
    if (submitted) {
      validate();
    }
  }, [myUser, submitted]);

  useEffect(() => {
    if(updateResult && updateResult.createOrUpdateMyUser) {
      setSuccess('Your profile settings have been saved');
      setSubmitted(false);
    }
  }, [updateResult]);

  const validate = () => {
    let errors = validatePersonalInfo(myUser, { validateRegistrationSurvey: false });
    if (myUser.country && isSanctioned(myUser.country)) {
      errors = { ...errors, country: `Using Neon applications from ${currentCountry?.label} is not allowed.` };
    }
    setInfoErrors(errors);
    return JSON.stringify(errors) === '{}';
  };

  const selectChangeHandler = (field: keyof MyUser) => (option: Option) => {
    setMyUser({
      ...myUser,
      [field]: option?.value,
    });
  }

  const changeHandler = (field: keyof MyUser) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMyUser({...myUser, [field]: e.target.value});
  }

  const updateMyUser = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSubmitted(true);
    if(!validate()) return;
    doUpdateMyUser({
      variables: {
        input: mapToUpdateMyUserInput(myUser)
      }
    });
  };

  if(loading) return <Loader />;

  return (<Fragment>
    <Snackbar
      open={!!profileError}
      message={profileError}
      type="error"
      onClose={() => setProfileError('')}
      autoHideDuration={3000}
    />
    <Snackbar
      open={!!success}
      message={success}
      type="success"
      onClose={() => setSuccess('')}
      autoHideDuration={3000}
    />
    <form className={classes.form}>
      <Grid item xs={12} sm={6} lg={4} container spacing={4} className={classes.formWrapper}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Heading styleName="headline1">Personal details</Heading>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email address"
                value={myUser.auth0email}
                size="large"
                helperText="The email address used for sign up can't be updated."
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="First name"
                value={myUser.firstName}
                onChange={changeHandler('firstName')}
                error={!!infoErrors.firstName}
                errorText={infoErrors.firstName}
                size="large"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Last name"
                value={myUser.lastName}
                onChange={changeHandler('lastName')}
                error={!!infoErrors.lastName}
                errorText={infoErrors.lastName}
                size="large"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Company"
                value={myUser.company}
                onChange={changeHandler('company')}
                error={!!infoErrors.company}
                errorText={infoErrors.company}
                size="large"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone number"
                value={myUser.phoneNumber}
                onChange={changeHandler('phoneNumber')}
                error={!!infoErrors.phoneNumber}
                errorText={infoErrors.phoneNumber}
                size="large"
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="What country are you based in?"
                options={countriesOptions}
                value={currentCountry}
                onChange={selectChangeHandler('country')}
                error={!!infoErrors.country}
                errorText={infoErrors.country}
                size="large"
              />
            </Grid>
          </Grid>
        </Grid>
        {categories.length > 0 && (
          <Grid item xs={12}>
            <Heading styleName="headline1" className={classes.spacer}>Your interests</Heading>
            {categories.map((category, index) => <div key={index}>
              <Checkbox
                checked={myUser.categories.indexOf(category) > -1}
                value={category}
                label={category}
                onChange={e => {
                  const checked = e.target.checked;
                  const categories = checked ? [...myUser.categories, category] : myUser.categories.filter(uc => uc !== category);
                  setMyUser({
                    ...myUser,
                    categories
                  });
                }}
              /><br/>
            </div>)}
          </Grid>)}
        <Grid item xs={12}>
          <Button size="large" type="submit" colorType="primary" onClick={updateMyUser} busy={updateLoading}>Update details</Button>
        </Grid>
      </Grid>
    </form>
  </Fragment>);
};

export default Profile;