import React from 'react';
import { ExtendedTheme, Link, TextBlock } from 'neon-design-system';
import { makeStyles, createStyles } from '@material-ui/styles';
import { baseUrls } from '../../../../helpers/urls';

interface PolicyLinksProps {
    type: 'signin' | 'signup';
    className?: string;
    responsive?: boolean;
}

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
    policyRoot: (props: PolicyLinksProps) => ({
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        ...(props.responsive && {
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'center'
            },
        }),
    }),
    policyContainer: (props: PolicyLinksProps) => ({
        display: 'flex',
        alignItems: 'center',
        '& p, & button': {
            color: 'inherit',
            textAlign: 'left'
        },
        '&:not(:first-of-type)': {
            '&:before': {
                display: 'inline-block',
                position: 'relative',
                content: '""',
                width: theme.spacing(0.5),
                height: theme.spacing(0.5),
                backgroundColor: theme.themeColors.grey[500],
                borderRadius: theme.spacing(0.5),
                margin: theme.spacing(0, 1),
            },
            ...(props.responsive && {
                [theme.breakpoints.down('xs')]: {
                    marginTop: theme.spacing(1),
                    '&:before': {
                        display: 'none'
                    },
                },
            }),
        },
    }),
}));

const PolicyLinks = (props: PolicyLinksProps) => {
    const classes = useStyles(props);
    const { type, className } = props;

    if(type === "signup") {
        return (
            <div className={className}>
                For further information about how we use your data, please refer to our&nbsp;
                <Link
                    to={baseUrls.termsOfUse}
                    ignoreRouter={true}
                    target="_blank"
                >
                    Terms of Use
                </Link>
                &nbsp;and&nbsp;
                <Link
                    to={baseUrls.cookiePolicy}
                    ignoreRouter={true}
                    target="_blank"
                >
                   Cookie Policy
                </Link>.
            </div>
        )
    }

    return (
        <div className={[classes.policyRoot, className].join(' ')}>
            {type === "signin" &&
                <div className={classes.policyContainer}>
                    <Link
                      to={baseUrls.termsOfService}
                      ignoreRouter={true}
                      target="_blank"
                    >
                        <TextBlock styleName="footnote">
                            Terms of Service
                        </TextBlock>
                    </Link>
                </div>
            }
            <div className={classes.policyContainer}>
                <Link
                    to={baseUrls.termsOfUse}
                    ignoreRouter={true}
                    target="_blank"
                >
                    <TextBlock styleName="footnote">
                        Terms of Use
                    </TextBlock>
                </Link>
            </div>
            {type === "signin" &&
                <div className={classes.policyContainer}>
                    <Link
                        to={baseUrls.privacy}
                        ignoreRouter={true}
                        target="_blank"
                    >
                        <TextBlock styleName="footnote">
                            Privacy Notice
                        </TextBlock>
                    </Link>
                </div>
            }
            <div className={classes.policyContainer}>
                <Link
                  to={baseUrls.cookiePolicy}
                  ignoreRouter={true}
                  target="_blank"
                >
                    <TextBlock styleName="footnote">
                        Cookie Policy
                    </TextBlock>
                </Link>
            </div>
        </div>
    );
};

export default PolicyLinks;