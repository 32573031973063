import { OperationDefinitionNode } from 'graphql';
import { ApolloLink, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error'
import { config } from '../config'
import * as log from "../helpers/logger";
import omitDeep from "omit-deep";

// link to capture and process any errors during a GraphQL call
// See:
// https://www.npmjs.com/package/apollo-link-error
// https://stackoverflow.com/questions/48863441/apollo-client-how-to-simply-debug-a-400-code-error
// https://www.apollographql.com/docs/link/composition/
// https://www.apollographql.com/docs/react/data/error-handling/
// https://blog.apollographql.com/full-stack-error-handling-with-graphql-apollo-5c12da407210
export const errorlink = onError(({ graphQLErrors, networkError, operation, response }) => {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      // logAsWarning flag must be returned from backend
      if (error.extensions && error.extensions.logAsWarning) {
        log.warn('GraphQL warning', {
          message: error.message,
          location: error.locations,
          path: error.path,
          extensions: error.extensions ? error.extensions.code : undefined,
        })
      } else {
        log.error('GraphQL error', undefined, {
          message: error.message,
          location: error.locations,
          path: error.path,
          extensions: error.extensions ? error.extensions.code : undefined,
        })
      }
    });
  }
  if (networkError) log.error('Network error', networkError);
});

// link to remove any __typename sent to a mutation. This occurs if the data being sent
// is a copy of data received from a query, which GraphQL adds __typename to maintain GraphQL
// types accross the network. This may at some point get built into GraphQL.
// See:
// https://github.com/apollographql/apollo-feature-requests/issues/6
// https://github.com/apollographql/apollo-client/issues/1564
export const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const def = getMainDefinition(operation.query) as OperationDefinitionNode;
    if (def && def.operation === "mutation") {
      operation.variables = omitDeep(operation.variables, ['__typename']);
      log.info(`Mutation Called: ${operation.operationName}`);
    }
    return forward ? forward(operation) : null;
 });

// The standard HTTP link for GraphQL
export const httpLink = new HttpLink({
    uri: `${config.dashboardServer}/graphql`
});
