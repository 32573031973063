import React from 'react';
import { Footer as NeonFooter } from 'neon-design-system';

interface FooterProps {
    className?: string;
}

const Footer = ({ className }: FooterProps) => <NeonFooter className={className} />;

export default Footer;
