import React, { useEffect, useState, Fragment } from 'react';
import { ExtendedTheme, Heading, TextBlock, TextField, Link, Button, MessageView, LargeSend } from 'neon-design-system';
import { Grid, makeStyles, createStyles, Box } from '@material-ui/core';
import { useWebAuth } from '../helpers/hooks/useWebAuth';
import ApplicationLogo from "../components/component-library/ProductInformation/ApplicationLogo/ApplicationLogo";
import { validateEmail } from "../helpers/common";
import { config } from "../config";
import Template from "../components/Template/Template";
import SplashScreenImage from "../components/component-library/Images/jpg/aside-2.jpg";
import SimpleFooter from '../components/component-library/Navigation/Footer/SimpleFooter';
import { useAuth0 } from 'neon-auth0';

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
    container: {
        maxWidth: theme.spacing(50),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    contactUs: {
        color: theme.palette.grey[400],
    },

    logo: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'left',
        },
    },
    header: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(8),
        },
    },
    subHeader: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
    form: {
        marginTop: theme.spacing(6),
    },
    sendRecoveryLink: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    goBack: {
        fontWeight: 'bold'
    },
    messageOffset: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(70),
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
}));

export const Forgot = () => {
    const authClient = useWebAuth();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { loginWithRedirect } = useAuth0();

    const classes = useStyles();

    const validate = () => {
        if(!email) {
            setEmailError('Please enter an email address');
            return;
        }

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        setEmailError('');
        return true;
    };

    useEffect(() => {
        if (submitted) {
            validate();
        }
    }, [email]);

    if(!authClient) return <></>;

    const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setSubmitted(true);
        if(!validate()) {
            return;
        }
        authClient.changePassword({
            connection: 'Username-Password-Authentication',
            email,
        }, (e, r) => setShowMessage(true));
    }

    return (
      <Template
        content={
          <Fragment>
              <Box className={classes.container}>
                  {showMessage ?
                    <div className={classes.messageOffset}>
                        <MessageView
                        styleName="grey"
                        icon={<LargeSend/>}
                        title="Please check your inbox"
                        children="We've just sent instructions for how to reset your password to your email address."
                        primaryAction={{
                            label: 'Go back to sign in',
                            onClick: () => loginWithRedirect(),
                            styleName: 'primary'
                          }}
                        />
                    </div>
                    :
                    <Fragment>
                        <ApplicationLogo logoClass={classes.logo} />
                        <Heading xs="small" sm="small" md="medium" lg="medium" xl="medium" styleName="largeTitle" className={classes.header}>
                            Reset your password
                        </Heading>
                        <TextBlock styleName="largeBody2" ink="muted" className={classes.subHeader}>
                            You will receive an email with a link to reset your password.
                        </TextBlock>
                        <form className={classes.form}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email address"
                                        helperText="Enter the email address you used to register."
                                        error={!!emailError}
                                        errorText={emailError}
                                        onChange={e => setEmail(e.target.value)}
                                        size="large"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button colorType="primary" type="submit" size="large" className={classes.sendRecoveryLink} onClick={submit}>
                                        Send recovery link
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link to='/' className={classes.goBack}>Go back to sign in</Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextBlock styleName="footnote" className={classes.contactUs}>
                                        For more help, please contact <Link ignoreRouter to={`mailto:${config.supportEmail}`}>Neon Support.</Link>
                                    </TextBlock>
                                </Grid>
                            </Grid>
                        </form>
                    </Fragment>
                  }
              </Box>
            </Fragment>
        }
        sideImage={SplashScreenImage}
        footer={showMessage ? undefined : <SimpleFooter />}
      />
    );
};
