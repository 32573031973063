import React from 'react';
import { makeStyles } from "@material-ui/core";
import { Link } from "neon-design-system";

const useStyles = makeStyles({
    link: {
        fontSize: 'inherit'
    },
});

export const NeonEmail = () => {
    const classes = useStyles();
    return <Link className={classes.link} ignoreRouter to={'mailto:neon@marex.com'}>neon@marex.com</Link>
};