import React, { useState, ChangeEvent, Fragment, useEffect } from 'react';
import {
  ExtendedTheme,
  TextBlock,
  Heading,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Checkbox,
  Loader,
  MessageView,
  LargeCheckCircle,
  Link,
  ErrorText,
  Select,
  Radio,
  Option
} from 'neon-design-system';
import { makeStyles, createStyles } from '@material-ui/styles';
import Template from "../components/Template/Template";
import ApplicationLogo from "../components/component-library/ProductInformation/ApplicationLogo/ApplicationLogo";
import { Box, Grid } from "@material-ui/core";
import { ClientStatus, MyUser, RegistrationSurvey, parseClientStatus } from '../graphQL/queries/myUser';
import UPDATE_MY_USER, { UpdateMyUserResult, UpdateMyUserRequest } from '../graphQL/mutations/updateMyUser';
import { useMutation, useQuery } from '@apollo/client';
import { baseUrls } from '../helpers/urls';
import { useQueryParams } from '../helpers/hooks/useQueryParams';
import { ProfileInfoErrors } from '../models';
import { validatePersonalInfo, partialInfoFromResponse, mapToUpdateMyUserInput } from '../helpers/profileInfo';
import { useAuth0, useAuth0Data } from 'neon-auth0';
import { IdToken } from '@auth0/auth0-react';
import { getCustomClaimFromIdToken } from '../helpers/auth0';
import { getReturnUrlFromIdToken, getReturnUrlFromLocation, clearReturnUrlFromStorage } from '../helpers/returnUrl';
import GET_MY_USER_WITH_CATEGORIES, { MyUserWithCategoriesQueryResult } from '../graphQL/queries/myUserWithCategories';
import SplashScreenImage from "../components/component-library/Images/png/Landing.png";
import SimpleFooter from '../components/component-library/Navigation/Footer/SimpleFooter';
import * as log from '../helpers/logger';
import { countriesOptions, isSanctioned } from '../helpers/countries';
import { Sanctioned } from '../components/Sanctioned';

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
  content: {
    maxWidth: theme.spacing(50),
  },
  logo: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'left',
    },
  },
  header: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(8),
    },
  },
  subHeader: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  textColor: {
    color: theme.themeColors.grey[400],
  },
  stepperWrapper: {
    margin: theme.spacing(3, 0),
  },
  buttonWrapper: {
    marginTop: theme.spacing(3),
    '& button': {
      marginRight: theme.spacing(1)
    }
  },
  signoutWrapper: {
    marginTop: theme.spacing(6),
  },
  signoutText: {
    color: theme.themeColors.grey[200],
  },
  checkboxOffset: {
    marginTop: theme.spacing(3)
  },
  agreementsWrapper: {
    backgroundColor: theme.themeColors.grey[800],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1.75, 2),
    borderRadius: theme.spacing(0.5),
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(0.5)
    }
  },
  agreementsLink: {
    position: 'relative',
    top: theme.spacing(0.25),
    marginLeft: theme.spacing(-1.5)
  },
  formSpacing: {
    marginTop: theme.spacing(2),
  },
  radioGroup: {
    "& > *": {
      marginRight: theme.spacing(1),
    }
  },
  radioGroupLabel: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
  },
  errorText: {
    marginTop: theme.spacing(0.5),
  },
}));

interface RegistrationCompleteProps {
  continueRegistration?: boolean;
}

const getInitialUserState = (idToken: IdToken, queryParams: URLSearchParams): MyUser => {
  const marexUser = idToken.email?.match(/@marexspectron.com$/) || idToken.email?.match(/@marex.com$/);
  return {
    auth0email: '',
    firstName: queryParams.get('firstName') || '',
    lastName: queryParams.get('lastName') || '',
    company: queryParams.get('company') || '',
    phoneNumber: '',
    categories: [],
    acceptedTerms: true,
    acceptedPrivacy: true,
    registrationSurvey: {
      clientStatus: parseClientStatus(queryParams.get('clientStatus')),
      marexContact: ''
    },
    ...(marexUser && {
      firstName: idToken.given_name || '',
      lastName: idToken.family_name || '',
      company: 'Marex',
      phoneNumber: getCustomClaimFromIdToken(idToken, 'telephonenumber') || ''
    })
  };
}

const RegistrationComplete = ({continueRegistration}: RegistrationCompleteProps) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isPersonalInfoChecked, setIsPersonalInfoChecked] = useState<boolean>(false);
  const { idToken } = useAuth0Data();
  const queryParams = useQueryParams();
  const [myUser, setMyUser] = useState<MyUser>(getInitialUserState(idToken, queryParams));
  const [categories, setCategories] = useState<string[]>([]);
  const {data, loading: categoriesUserLoading} = useQuery<MyUserWithCategoriesQueryResult>(GET_MY_USER_WITH_CATEGORIES, {
    fetchPolicy: 'network-only',
  });
  const [infoErrors, setInfoErrors] = useState<ProfileInfoErrors>({});
  const [doUpdateMyUser, {loading, data: updateResult}] = useMutation<UpdateMyUserResult, UpdateMyUserRequest>(UPDATE_MY_USER, {
    onError: () => {}, // swallow error
  });
  const {logout} = useAuth0();

  useEffect(() => {
    let availableCategories: string[] = [];
    if(data && data.categories) {
      availableCategories = data.categories.map(category => category.name);
      setCategories(availableCategories);
    }
    if(data && data.myUser) {
      const myUserDetails = partialInfoFromResponse(data.myUser);
      const categories = myUserDetails.categories?.filter(c => availableCategories.indexOf(c) !== -1) || [];
      setMyUser({
        ...myUser,
        ...myUserDetails,
        registrationSurvey: {
          clientStatus: myUserDetails.registrationSurvey?.clientStatus ?? myUser.registrationSurvey?.clientStatus,
          marexContact: myUserDetails.registrationSurvey?.marexContact ?? ''
        },
        categories,
      });
    }
  }, [data]);

  useEffect(() => {
    if (isPersonalInfoChecked && infoErrors) {
      validate();
    }
  }, [myUser]);

  useEffect(() => {
    LogStepUpdate('ChangeActiveStep', {ActiveStep: activeStep});
  }, [activeStep]);

  const returnUrlFromLocation = getReturnUrlFromLocation();
  const returnUrl = returnUrlFromLocation || getReturnUrlFromIdToken(idToken);

  const changeActiveStepBy = (stepChange: number) => {
    setActiveStep(currentValue => currentValue + stepChange);
  }

  const changeHandler = (field: keyof MyUser) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMyUser({
      ...myUser,
      [field]: e.target.value,
    });
  }

  const selectChangeHandler = (field: keyof MyUser) => (option: Option) => {
    setMyUser({
      ...myUser,
      [field]: option?.value,
    });
  }

  const registrationSurveyChangeHandler = (field: keyof RegistrationSurvey) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMyUser({
      ...myUser,
      registrationSurvey: {
        ...myUser.registrationSurvey,
        [field]: e.target.value,
      }
    });
  }

  const validate = () => {
    const errors = validatePersonalInfo(myUser);
    setInfoErrors(currentErrors => {
      if (JSON.stringify(currentErrors) != JSON.stringify(errors))
      {
        LogStepUpdate('FormValidation', {formValidationErrors: errors});
      }
      return errors;
    });
    return JSON.stringify(errors) === '{}';
  };

  const LogStepUpdate = (stepName: string, additionalValues?: any) => {
    const step = {
      stepName: stepName,
      ...additionalValues
    };
    log.info('Dashboard Signup process update', step);
  }

  const checkPersonalInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsPersonalInfoChecked(true);
    if (myUser.country && isSanctioned(myUser.country)) {
      submit();
    }
    if (!validate()) {
      return;
    } else {
      setIsPersonalInfoChecked(false);
      changeActiveStepBy(1);
    }
  }

  const submit = () => {
    LogStepUpdate('SubmitSent');
    doUpdateMyUser({
      variables: {
          input: mapToUpdateMyUserInput(myUser)
      }
    }).then(response =>
      LogStepUpdate('SubmitResponse', {SubmitResponseValue: "Success"})
    ).catch(error =>
      LogStepUpdate('SubmitResponse', {...error, SubmitResponseValue: "Failure"})
    );
  };

  const completeSteps = <Box px={2}>
    {continueRegistration && <ApplicationLogo logoClass={classes.logo} />}
    <div className={classes.header}>
      <Heading xs="small" sm="small" md="medium" lg="medium" xl="medium" styleName="largeTitle">{continueRegistration ? `Complete your profile` : 'We want to know you better'}</Heading>
    </div>
    <div className={classes.subHeader}>
      <TextBlock xs="small" sm="small" md="medium" lg="medium" xl="medium" styleName="largeBody2" className={classes.textColor}>
        {continueRegistration ? 'Please provide your details to help us identify you' : 'In order to access this application, please fill in your details'}
      </TextBlock>
    </div>
    <div className={classes.stepperWrapper}>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>{continueRegistration ? 'Activate account' : 'Active account'}</StepLabel>
        </Step>
        <Step>
          <StepLabel>Personal details</StepLabel>
        </Step>
        <Step>
          <StepLabel>Your interests</StepLabel>
        </Step>
      </Stepper>
    </div>
    <form>
      <div>
        {
          activeStep === 1 ? (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    value={myUser.firstName}
                    onChange={changeHandler('firstName')}
                    error={!!infoErrors.firstName}
                    errorText={infoErrors.firstName}
                    size="large"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Last Name"
                    value={myUser.lastName}
                    onChange={changeHandler('lastName')}
                    error={!!infoErrors.lastName}
                    errorText={infoErrors.lastName}
                    size="large"
                  />
                </Grid>
              </Grid>
              <div className={classes.formSpacing}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      label="Company"
                      value={myUser.company}
                      onChange={changeHandler('company')}
                      error={!!infoErrors.company}
                      errorText={infoErrors.company}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.formSpacing}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone number"
                      value={myUser.phoneNumber}
                      onChange={changeHandler('phoneNumber')}
                      error={!!infoErrors.phoneNumber}
                      errorText={infoErrors.phoneNumber}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.formSpacing}>
                <Grid container>
                  <Grid item xs={12} className={classes.radioGroup}>
                    <TextBlock styleName="caption1" className={classes.radioGroupLabel}>
                      Are you a client of the Marex group of companies?
                      </TextBlock>
                    <Radio
                      checked={myUser.registrationSurvey?.clientStatus === ClientStatus.CLIENT}
                      value={ClientStatus.CLIENT}
                      onChange={registrationSurveyChangeHandler('clientStatus')}
                      label="Yes"
                    />
                    <Radio
                      checked={myUser.registrationSurvey?.clientStatus === ClientStatus.NONCLIENT}
                      value={ClientStatus.NONCLIENT}
                      onChange={registrationSurveyChangeHandler('clientStatus')}
                      label="No"
                    />
                    <Radio
                      checked={myUser.registrationSurvey?.clientStatus === ClientStatus.ONBOARDING}
                      value={ClientStatus.ONBOARDING}
                      onChange={registrationSurveyChangeHandler('clientStatus')}
                      label="Currently onboarding"
                    />
                    <TextBlock styleName="caption1" ink="muted">
                      Including any divisions
                    </TextBlock>
                    {infoErrors.clientStatus &&
                      <ErrorText className={classes.errorText}>{infoErrors.clientStatus}</ErrorText>
                    }
                  </Grid>
                </Grid>
              </div>
              {(myUser.registrationSurvey?.clientStatus === ClientStatus.ONBOARDING || myUser.registrationSurvey?.clientStatus === ClientStatus.CLIENT) &&
              <div className={classes.formSpacing}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      label="Who is your contact at Marex?"
                      value={myUser.registrationSurvey?.marexContact}
                      onChange={registrationSurveyChangeHandler('marexContact')}
                      error={!!infoErrors.marexContact}
                      errorText={infoErrors.marexContact}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </div>
              }
              <div className={classes.formSpacing}>
                <Select
                  label="Which country are you based in?"
                  options={countriesOptions}
                  value={countriesOptions.find(c => c.value === myUser.country)}
                  onChange={selectChangeHandler('country')}
                  size="large"
                  error={!!infoErrors.country}
                  errorText={infoErrors.country}
                />
              </div>
            </div>
          ) : (
            <div>
              {categories.length > 0 && (<Fragment>
                <TextBlock xs="small" styleName="largeBody2" className={classes.textColor}>
                  To tailor the content we suggest to you on the Neon platform, please choose the channels that are most relevant to you.
                </TextBlock>
                <Box mt={3}>
                  {categories.map((category, index) => <div key={index}>
                    <Checkbox
                      checked={myUser.categories.indexOf(category) > -1}
                      value={category}
                      label={category}
                      onChange={e => {
                        LogStepUpdate(category, {[`${category}Accepted`]: e.target.checked});
                        const checked = e.target.checked;
                        const categories = checked ? [...myUser.categories, category] : myUser.categories.filter(uc => uc !== category);
                        setMyUser({
                          ...myUser,
                          categories,
                        });
                      }}
                    /><br/>
                  </div>)}
                </Box></Fragment>)}
              <Box mt={2} className={classes.agreementsWrapper}>
                <div>
                  <Checkbox
                    checked={!!myUser.acceptedTerms}
                    value={myUser.acceptedTerms ? "1" : "0"}
                    label="I agree to the Marex"
                    onChange={(event) => {
                      LogStepUpdate('Terms', {TermsAccepted: event.target.checked});
                      setMyUser({
                        ...myUser,
                        acceptedTerms: event.target.checked
                        });
                      }}
                  />
                  <Link
                    className={classes.agreementsLink}
                    to={baseUrls.termsOfService}
                    ignoreRouter={true}
                    target="_blank"
                  >
                    Terms of Service
                  </Link>
                </div>
                <div>
                  <Checkbox
                    checked={!!myUser.acceptedPrivacy}
                    value={myUser.acceptedPrivacy ? "1" : "0"}
                    label="I have read the Marex"
                    onChange={(event) => {
                      LogStepUpdate('Privacy', {TermsAccepted: event.target.checked});
                      setMyUser({
                      ...myUser,
                      acceptedPrivacy: event.target.checked
                    });
                  }}
                  />
                  <Link
                    className={classes.agreementsLink}
                    to={baseUrls.privacy}
                    ignoreRouter={true}
                    target="_blank"
                  >
                    Privacy Notice
                  </Link>
                </div>
              </Box>
            </div>
          )
        }
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          colorType="grey"
          disabled={activeStep === 1}
          onClick={() => {
            changeActiveStepBy(-1);
          }}
        >
          Back
        </Button>
        <Button
          colorType="primary"
          type="submit"
          onClick={activeStep === 1 ? checkPersonalInfo : submit}
          disabled={activeStep === 2 && (!myUser.acceptedTerms || !myUser.acceptedPrivacy)}
        >
          Next
        </Button>
      </div>
    </form>

    <div className={classes.signoutWrapper}>
        <TextBlock xs="medium" styleName="footnote" className={classes.signoutText}>
          <Link onClick={() => {
            clearReturnUrlFromStorage();
            logout({returnTo: window.location.origin})
          }}><b>Sign out</b></Link> and resume the profile completion process later.
        </TextBlock>
    </div>
  </Box>;

  const content = updateResult ?
    updateResult.createOrUpdateMyUser.country && isSanctioned(updateResult.createOrUpdateMyUser.country)
      ? <Sanctioned />
      : <Box mx="auto" className={classes.content}>
          <Box mt={8}>
            <MessageView
              styleName="grey"
              icon={<LargeCheckCircle/>}
              title="You're all set"
              children="Your profile is now complete, thank you!"
              primaryAction={{
                label: "Get started",
                onClick: () => {
                  window.location.replace(returnUrl || `${window.location.origin}${baseUrls.dashboard}`);
                },
                styleName: 'primary'
              }}
              secondaryAction ={{
                label: 'Sign out',
                onClick: () => logout({returnTo: window.location.origin}),
                styleName: 'grey'
              }}
            />
          </Box>
        </Box>
    : <Box mx="auto" className={classes.content}>
      {completeSteps}
    </Box>;

  return (loading || categoriesUserLoading) ? <Loader /> :
    continueRegistration ? (
      <Template
        content={content}
        sideImage={SplashScreenImage}
        footer={updateResult ? undefined : <SimpleFooter />}
      />
    ) : content;
};

export default RegistrationComplete;
