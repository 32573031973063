import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Container, ExtendedTheme } from "neon-design-system";
import Footer from "../component-library/Navigation/Footer/Footer";
import ApplicationLogo from "../component-library/ProductInformation/ApplicationLogo/ApplicationLogo";

interface StaticLayoutProps {
  children: React.ReactNode;
  showFooter?: boolean;
}

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
  root: { // IE needs parent element to be flex before 100vh is used; no idea
    display: 'flex'
  },
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
  },
  column: {
    flexDirection: 'column',
  },
  logo: {
    margin: theme.spacing(6, 0, 0, 0),
  },
}));

const StaticLayout = ({ children, showFooter }: StaticLayoutProps) => {
  const classes = useStyles();

  return <div className={classes.root}>
    <div className={classes.fullHeight}>
      <Container className={classes.column}>
        <ApplicationLogo className={classes.logo} />
        {children}
      </Container>
      {showFooter && <div><Footer /></div>}
    </div>
  </div>;
};

export default StaticLayout;
