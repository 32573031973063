import React, { useState } from 'react';
import { Button, ExtendedTheme } from 'neon-design-system';
import { makeStyles, Grid } from '@material-ui/core';
import { baseUrls } from '../../helpers/urls';
import { OneTrustPolicyWrapper } from './OneTrustPolicyWrapper';
import { config } from '../../config';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
	// The shipped Cookie List CSS is littered with bad/conflicting declarations so unfortunately have to use !important heavily to wrangle it
	oneTrustCookieListWrapper: {
		'& .ot-sdk-cookie-policy': {
			padding: 0,
			color: `${theme.themeColors.grey[200]} !important`,
			'& h3#cookie-policy-title': {
				fontSize: '36px',
				fontStyle: 'normal',
				fontWeight: 600,
				lineHeight: '48px',
				letterSpacing: '-0.01em',
				color: `${theme.themeColors.grey[200]} !important`,
				margin: theme.spacing(4, 0, 0, 0)
			},
			'& #cookie-policy-description, & p': {
				color: `${theme.themeColors.grey[200]} !important`,
				margin: '8px 0 !important',
				fontSize: '14px !important',
				lineHeight: '20px !important',
			},
			'& h4': {
				fontSize: '18px',
				fontStyle: 'normal',
				fontWeight: 'bold',
				lineHeight: '24px',
				color: `${theme.themeColors.grey[200]} !important`,
				margin: `${theme.spacing(4, 0, 0, 0)} !important`
			},
			// The actual table stylings
			'& table': {
				borderCollapse: 'collapse !important',
				borderSpacing: 0,
				borderRadius: '0 !important',
				margin: `${theme.spacing(2, 0, 0, 0)} !important`,
				'& thead, & th': {
					backgroundColor: 'transparent !important',
				},
				'& td, & th': {
					padding: '4px !important',
					'&, & span': {
						fontSize: '14px !important',
						lineHeight: '20px !important',
						color: `${theme.themeColors.grey[200]} !important`,
					},
					'& a': {
						backgroundColor: 'transparent !important',
						textDecoration: 'none !important',
						color: `${theme.themeColors.secondary[500]} !important`,
						fontSize: '14px !important',
						'&:hover': {
							color: `${theme.themeColors.secondary[700]} !important`,
						},
					}
				},
			},
		},
	},
}));

const CookieList = () => {
	const classes = useStyles();
	// The OneTrust looks for this magic div to inject the Cookie List markup
	return <div id='ot-sdk-cookie-policy' className={classes.oneTrustCookieListWrapper}></div>;
};

const openOneTrustPrivacyPreferenceCenter = () => document.getElementById('ot-sdk-btn')?.click();

export const CookiePolicy = () => {
	const [loaded, setLoaded] = useState<boolean>(false);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item>
					<OneTrustPolicyWrapper
						policyId={config.oneTrustCookiePolicyId}
						onPolicyLoad={function wireUpDynamicLinks() {
							const privacyPolicyLink = document.querySelector<HTMLLinkElement>('.otnotice-content a[href="privacy-policy"');
							if (privacyPolicyLink) {
								privacyPolicyLink.href = baseUrls.privacy;
								privacyPolicyLink.target = '_blank';
							}

							const privacyPreferenceCenterLink = document.querySelector<HTMLLinkElement>('.otnotice-content a[href="privacy-preference-centre"');
							if (privacyPreferenceCenterLink) {
								privacyPreferenceCenterLink.onclick = (event: Event) => {
									event.preventDefault();
									openOneTrustPrivacyPreferenceCenter();
								};
							}
							setLoaded(true);
						}}
					/>
				</Grid>
				<Grid item>
					{loaded && <Button size="medium" colorType="grey" onClick={openOneTrustPrivacyPreferenceCenter}>Manage Cookie Settings</Button>}
				</Grid>
			</Grid>
			<CookieList />
		</>
	);
}
