import React from 'react';
import { TextBlock, Link } from 'neon-design-system';

const CopyrightText = () => {
    const currentYear = new Date().getFullYear();
    const copyrightText = `© Copyright ${currentYear} Marex. Neon is a registered trademark of Marex Spectron Group Ltd. For more info, visit`;
    return <TextBlock xs="medium" styleName="caption1" ink="muted">{copyrightText} <Link ignoreRouter to="//www.marex.com">marex.com</Link>.</TextBlock>
}

export default CopyrightText;
