import React, { Fragment } from 'react';
import Template from "../Template/Template";
import sideImage from '../component-library/Images/jpg/trader.jpg';
import DownloadAppContent from './DownloadAppContent';
import SimpleFooter from "../component-library/Navigation/Footer/SimpleFooter";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { TextBlock, Button, ExtendedTheme, Logomark } from "neon-design-system";
import { config } from "../../config";

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
  footer: {
    textAlign: 'center',
    maxWidth: 'initial',
  },
  logo: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

export const TraderLayout = () => {
  const classes = useStyles();

  return (
      <Template
        content={
          <DownloadAppContent
            logo={<Logomark app="Risk" className={classes.logo} />}
            logoTitle="Risk"
            title="Download Neon Risk"
            description="Post trade Risk Platform providing enhanced risk management tools for Risk Managers and Trading Groups."
            content={
              <Fragment>
                <Button colorType="primary" size="large" href={config.neonRiskUrl}>Download and run</Button>
                <Box mt={4} mb={1}>
                  {
                    config.neonRiskVersion && (
                        <TextBlock styleName="caption1" ink="muted">Version {config.neonRiskVersion}</TextBlock>
                    )
                  }
                </Box>
              </Fragment>
            }
            versionDescription="Neon Risk requires Windows 10 and Microsoft .NET Framework 4.6 (x86 and x64)"
          />
        }
        footer={<SimpleFooter contentClassName={classes.footer} />}
        sideImage={sideImage}
      />
    );
};

export default TraderLayout;