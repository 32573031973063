import { JL } from 'jsnlog';
import { config, LogLevel } from '../config';

export interface SessionData {
  sessionId: string;
  appName: string;
  appVersion: string;
}

export interface PlatformData {
  cookies: boolean,
  browser: any,
  browserVersion: any,
  os: string,
  osVersion: string,
  osName: string,
  platform: string,
};

const getTimeStamp = () => new Date().toISOString();

let neonUserId: string;
let sessionData: SessionData;
let platformData: PlatformData;

export const setNeonUserId = (id: string) => neonUserId = id;
export const setSessionData = (data: SessionData) => sessionData = data;

const formatLogObject = (message: string, level: LogLevel, data?: any, e?: any) => {
  JL().log(level, {
    time: getTimeStamp(),
    app: sessionData?.appName,
    clientName: sessionData?.appName,
    clientVersion: sessionData?.appVersion,
    neonUserId,
    sessionId: sessionData?.sessionId,
    type: LogLevel[level],
    platformData: platformData,
    message,
    data
  }, e);
};

export const trace = (message: string, data?: any) => {
  formatLogObject(message, LogLevel.TRACE, data);
};

export const debug = (message: string, data?: any) => {
  formatLogObject(message, LogLevel.DEBUG, data);
};

export const info = (message: string, data?: any) => {
  formatLogObject(message, LogLevel.INFO, data);
};

export const warn = (message: string, data?: any) => {
  formatLogObject(message, LogLevel.WARN, data);
};

export const error = (message: string, e?: any, data?: any) => {
  formatLogObject(message, LogLevel.ERROR, data, e);
};

export const fatalError = (message: string, e?: any, data?: any) => {
  formatLogObject(message, LogLevel.FATAL, data, e);
};

const getPlatformData = () => {
  const bowser = require('bowser');
  const browser = bowser.getParser(window.navigator.userAgent);

  const cookies = window.navigator.cookieEnabled;
  const browserInfo = browser.parse();

  return {
    cookies: cookies,
    browser: browserInfo.parsedResult.browser.name,
    browserVersion: browserInfo.parsedResult.browser.version,
    os: browserInfo.parsedResult.os.name,
    osVersion: browserInfo.parsedResult.os.version,
    osName: browserInfo.parsedResult.os.versionName,
    platform: browserInfo.parsedResult.platform.type
  };
};

const loggerInit = () => {
  const consoleAppender =  JL.createConsoleAppender('consoleAppender');
  const appenders = [consoleAppender]

  if (consoleAppender.setOptions) {
    consoleAppender.setOptions({ level: config.logging.level });
  }

  if (config.sendLogs) {
    const ajaxAppender = JL.createAjaxAppender('ajaxAppender');
    if (ajaxAppender.setOptions) {
      ajaxAppender.setOptions({...config.logging});
    }
    appenders.push(ajaxAppender)
  }

  try {
    platformData = getPlatformData();
  }
  catch(e) {
    
  }
  

  JL().setOptions({
    appenders,
  });
};

loggerInit();
