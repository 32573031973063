import React, { Fragment, useEffect } from 'react';
import { makeStyles, createStyles, Card, CardMedia, CardContent, Grid, CardActions } from '@material-ui/core';
import { config } from '../config';
import { TextBlock, Button, ApplicationLogo, MessageView, LargeWarningCircle, Heading } from 'neon-design-system';
import * as log from '../helpers/logger';
import Template from './Template/Template';
import SplashScreenImage from "../components/component-library/Images/jpg/aside-3.jpg";
import { useLocation } from "react-router-dom";
import { getUrlParams } from "../helpers/common";
import { ExpiredLinkError } from './ExpiredLinkError';

const useStyles = makeStyles(theme => createStyles({
	container: {
		paddingTop: theme.spacing(8),
		maxWidth: theme.spacing(87.5),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	offset: {
		paddingTop: theme.spacing(20),
	},
	applicationsGrid: {
		marginTop: theme.spacing(2),
		alignItems: 'center',
      	justifyContent: 'center',
	},
	card: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(2)
		},
		[theme.breakpoints.up('sm')]: {
			marginRight: theme.spacing(2)
		},
		'&:last-child': {
			marginRight: 0,
		},
	},
	cardContent: {
		paddingTop: theme.spacing(2),
	},
	cardActions: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(3),
		display:'flex',
		justifyContent:'center'
	},
	cardDescription: {
		display:'flex',
		justifyContent:'center',
	},
	media: {
		marginTop: theme.spacing(3),
		display:'flex',
		justifyContent:'center',
		alignItems: 'center',
		height: theme.spacing(4)
	},
	contactHeader: {
		marginTop: theme.spacing(6),
		textAlign: 'center'
	},
	accessExpiredViewWrapper: {
		marginBottom: theme.spacing(6),
	},
	neonLogo: {
		height: theme.spacing(4),
		width: 'auto',
	}
}));

export const ErrorCard = (props: {
	imageUrl: string,
	heading: string,
	email: string,
	tel: string,
	link: string,
	linkMessage: string,
	NDSAppLogoName?: string
}) => {
	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<CardMedia title={props.heading} className={classes.media}>
				{props.NDSAppLogoName ? <ApplicationLogo app="Neon" className={classes.neonLogo} /> : <img src={props.imageUrl} alt={props.heading} />}
			</CardMedia>
			<CardContent className={classes.cardContent}>
				<TextBlock styleName="body" className={classes.cardDescription}>
					Email: {props.email}
				</TextBlock>
				<TextBlock styleName="body" className={classes.cardDescription}>
					Phone: {props.tel}
				</TextBlock>
			</CardContent>
			<CardActions className={classes.cardActions}>
				<Button colorType="primary" size="small" onClick={() => window.location.href = props.link}>
					{props.linkMessage}
				</Button>
			</CardActions>
		</Card>
	);
}

interface Action {
	label: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface ErrorProps {
	heading?: string,
	headingElement?: JSX.Element,
	headline?: string,
	primaryAction?: Action;
	disableDescription?: boolean;
};

const Error = ({ heading, headline, primaryAction, disableDescription = false}: ErrorProps) => {
	const location = useLocation();
	const defaultHeading = "An error has occurred.";
	const defaultHeadLine = "Please try again later.";
	const classes = useStyles();
	const errorDescription = getUrlParams(location.search, 'error_description');
	const messageDescription = getUrlParams(location.search, 'error_description');
	const accessExpired  = (errorDescription || messageDescription)?.includes('Access expired');
	const usedHeading = heading || defaultHeading;
	const usedHeadline = headline || defaultHeadLine;


	useEffect(() => {
        log.error('Error occured in dashboard', {heading: usedHeading, headline: usedHeadline});
    }, [heading, headline]);

	if(accessExpired) return <ExpiredLinkError />;

	return (
	<Template
		content={
		<Fragment>
			<div className={[classes.container, disableDescription && classes.offset].filter(n => !!n).join(' ')}>
				<div>
					<MessageView
						styleName="primary"
						icon={<LargeWarningCircle/>}
						size="large"
						title={usedHeading}
						primaryAction={primaryAction}>
						{usedHeadline}
					</MessageView>
				</div>
				{
					!disableDescription && (
						<>
						{<div className={classes.contactHeader}><Heading styleName="headline3">If the problem persists, please contact support using the following contact details:</Heading></div>}
							<Grid container className={classes.applicationsGrid}>
								<ErrorCard
									heading="Neon"
									imageUrl="/images/neon-logo.png"
									tel="+44 (0) 20 7655 6006"
									email="neonsupport@marex.com"
									link={config.baseUrl}
									linkMessage="Go to Neon"
									NDSAppLogoName="Neon" />
							</Grid>
						</>
					)
				}
			</div>
		</Fragment>
		}
		sideImage={SplashScreenImage}
	/>
	);
};

export default Error;
