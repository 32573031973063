import React, { useState } from 'react';
import { useWebAuth } from '../helpers/hooks/useWebAuth';
import { Loader } from 'neon-design-system';
import StaticLayout from './StaticLayout/StaticLayout';
import { Box } from '@material-ui/core';
import { config } from '../config';

export const OneTrustLogin = () => {
    const authClient = useWebAuth();
    const [error, setError] = useState('');
    if(!authClient) return <Loader/>;

    const form = React.createRef<HTMLFormElement>();
    const login = (e: any) => {
        e.preventDefault();
        const email = (form.current![0] as HTMLInputElement).value;
        const password = (form.current![1] as HTMLInputElement).value;
        authClient.login({
            responseType: 'code',
            responseMode: 'query',
            redirectUri: config.auth.redirectUri + '2',
            email,
            password,
        }, (err) => {
            setError(err?.error_description || '');
            throw err;
        })
    }

    return <StaticLayout>
        <Box width="100%" mt={5}>
            {error}
            <form name="login" onSubmit={login} ref={form}>
                <input type="text" id="email" name="email"/>
                <input type="password" id="password" name="password"/>
                <button type="submit" name="submit">Login</button>
            </form>
        </Box>
    </StaticLayout>;
}
