import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Auth0RedirectLogon from './components/auth0/Auth0RedirectLogon';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline'
import { WithTheme } from 'neon-design-system';
import { SessionDataProvider } from "./contexts/SessionDataProvider";
import TagManager from 'react-gtm-module';
import { config } from "./config";

const tagManagerArgs = {
	gtmId: config.googleTagManagerCertificate
}

TagManager.initialize(tagManagerArgs)

declare global {
	interface Window {
		// window declarations so the OneTrust policy script can be called witout any compiler warnings
		OneTrust: {
			NoticeApi: {
				Initialized: Promise<never>;
				LoadNotices: (notices: string[], asdf: boolean) => Promise<never>;
			 };
		}
	}
}

// If this cookie is present then the auth0-spa library will attempt a silent login
// If this cookie is not present, then the user starts in an unauthenticated state
// and will be sent to the lock screen to log in
document.cookie = 'auth0.is.authenticated=true;path=/;max-age=86400';

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const AppContainer = () => {
	return (
		<WithTheme options={{ includeOneTrustTheme: false }}>
			<CssBaseline/>
			<SessionDataProvider>
				<Auth0RedirectLogon />
			</SessionDataProvider>
		</WithTheme>
	)
}

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<AppContainer />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'));
