import React from 'react';
import { LargeWarningCircle, MessageView } from 'neon-design-system';
import { NeonEmail } from './NeonEmail';

export const Sanctioned = () => {
    return <div style={{ alignSelf: 'center', width: '100%' }}>
        <MessageView
            styleName="grey"
            icon={<LargeWarningCircle/>}
            title="Marex products and services are not available in your jurisdiction"
        >
            If you believe you entered a value incorrectly on the previous page, please get in touch with <NeonEmail />
        </MessageView>
    </div>;
}