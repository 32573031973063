import React, { useEffect, useState, Fragment } from 'react';
import { Heading, TextBlock, Button, ExtendedTheme, Checkbox, AlertBox, Link, Snackbar } from 'neon-design-system';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { baseUrls } from '../../helpers/urls';
import { useMutation, useQuery } from "@apollo/client";
import UPDATE_MY_USER, {
  UpdateMyUserRequest,
  UpdateMyUserResult
} from "../../graphQL/mutations/updateMyUser";
import GET_MY_USER, { MyUserQueryResult } from '../../graphQL/queries/myUser';

const privacyStyles = makeStyles((theme: ExtendedTheme) => ({
  spacer: {
    marginBottom: theme.spacing(1),
  },
  cookiesLink: {
    display: 'initial'
  }
}));

export const Privacy = () => {
  const classes = privacyStyles();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState<boolean>(false);
  const [originalAgreements, setOriginalAgreements] = useState<{ acceptedTerms: boolean, acceptedPrivacy: boolean }>({ acceptedTerms: false, acceptedPrivacy: false });
  const [agreementsUpdated, setAgreementsUpdated] = useState<boolean>(false);
  const {data, loading} = useQuery<MyUserQueryResult>(GET_MY_USER, {fetchPolicy: 'network-only'});
  const [doUpdateMyUser, {data: updateResult, loading: updateLoading}] = useMutation<UpdateMyUserResult, UpdateMyUserRequest>(UPDATE_MY_USER, {
    onError: e => {
      setError(e.toString());
    }
  });

  useEffect(() => {
    if(data && data.myUser) {
      const userAcceptedTerms = data.myUser?.acceptedTerms || false;
      setAcceptedTerms(userAcceptedTerms);

      const userAcceptedPrivacy = data.myUser?.acceptedPrivacy || false;
      setAcceptedPrivacy(userAcceptedPrivacy);

      setOriginalAgreements({ acceptedTerms: userAcceptedTerms, acceptedPrivacy: userAcceptedPrivacy });
    }
  }, [data]);

  useEffect(() => {
    if(updateResult && updateResult.createOrUpdateMyUser) {
      setOriginalAgreements({ acceptedTerms, acceptedPrivacy });
      setSuccess('Your privacy settings have been saved');
    }
  }, [updateResult]);

  useEffect(() => {
    setAgreementsUpdated(originalAgreements.acceptedPrivacy !== acceptedPrivacy || originalAgreements.acceptedTerms !== acceptedTerms);
  }, [acceptedTerms, acceptedPrivacy, originalAgreements]);

  const updatePrivacy = () => {
    doUpdateMyUser({
      variables: {
        input: {
          acceptedTerms,
          acceptedPrivacy
        }
      }
    });
  };

  return (<Fragment>
    <Snackbar
      open={!!error}
      message={error}
      type="error"
      onClose={() => setError('')}
      autoHideDuration={3000}
    />
    <Snackbar
      open={!!success}
      message={success}
      type="success"
      onClose={() => setSuccess('')}
      autoHideDuration={3000}
    />
    <Grid item xs={12}>
      <AlertBox styleName="grey" visible>
        Please note that if you opt-out from Terms of Service and Cookie Policy you won't be able to access some or all the products within the Neon platform.
      </AlertBox>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Heading styleName="headline1" className={classes.spacer}>Terms of Use</Heading>
        </Grid>
        <Grid item xs={12}>
          <TextBlock styleName="footnote" ink="muted">For further details, please visit the <Link to={baseUrls.termsOfUse} target="_blank">Terms of Use</Link> page.</TextBlock>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Heading styleName="headline1" className={classes.spacer}>Cookie Policy</Heading>
        </Grid>
        <Grid item xs={12}>
          <Button size="medium" colorType="grey" className={classes.spacer} onClick={() => document.getElementById('ot-sdk-btn')?.click()}>Manage Cookie Settings</Button>
        </Grid>
        <Grid item xs={12}>
          <TextBlock styleName="footnote" ink="muted">For further details, please review our <Link to={baseUrls.cookiePolicy} target="_blank">Cookie Policy</Link>.</TextBlock>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Heading styleName="headline1" className={classes.spacer}>Privacy Notice</Heading>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={acceptedPrivacy}
            value={acceptedPrivacy ? "1" : "0"}
            label="I have read the Marex Privacy Notice"
            onChange={(event: { target: { checked: boolean; } }) => {
              setAcceptedPrivacy(event.target.checked);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock styleName="footnote" ink="muted">For further details, please visit the <Link to={baseUrls.privacy} target="_blank">Privacy Notice</Link> page.</TextBlock>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Heading styleName="headline1" className={classes.spacer}>Terms of Service</Heading>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={acceptedTerms}
            value={acceptedTerms ? "1" : "0"}
            label="I agree to the Marex Terms of Service"
            onChange={(event: { target: { checked: boolean; } }) => {
              setAcceptedTerms(event.target.checked);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock styleName="footnote" ink="muted">For further details, please visit the <Link to={baseUrls.termsOfService} target="_blank">Terms of Service</Link> page.</TextBlock>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Heading styleName="headline1" className={classes.spacer}>Terms of Service for Premium Content</Heading>
        </Grid>
        <Grid item xs={12}>
          <TextBlock styleName="footnote" ink="muted">For further details, please visit the <Link to={baseUrls.termsOfServiceForPremiumContent} target="_blank">Terms of Service for Premium Content</Link> page.</TextBlock>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Button size="large" colorType="primary" onClick={updatePrivacy} disabled={!agreementsUpdated} busy={updateLoading}>Update details</Button>
    </Grid>
  </Fragment>);
}

export default Privacy;
