// Base App urls
export const baseUrls = {
    splashScreen: '/',
    signup: '/signup',
    forgot: '/forgot',
    termsOfService: '/termsOfService',
    termsOfServiceForPremiumContent: '/neonpremiumcontenttermsofservice',
    privacy: '/privacy',
    termsOfUse: '/termsOfUse',
    cookiePolicy: '/cookie-policy',
    dashboard: '/dashboard',
    account: '/account',
    manageAccount: '/manage-account',
    emailverification: '/emailverification',
    error: '/error',
    complete: '/complete',
    oneTrustLogin: '/onetrustlogin',
    passwordUpdated: '/password-updated',
    neonTraderDownload: '/neon-trader-download',
    neonRiskDownload: '/neon-risk-download',
    login: '/login',
    loginMfa: '/loginMfa',
  };
