import React from 'react';
import { useAuth0 } from 'neon-auth0';
import { Loader } from 'neon-design-system';

export const InterceptAuth0Callback2 = ({children}: {children: React.ReactNode}) => {
    const { isLoading, loginWithRedirect } = useAuth0();
    if (isLoading) {
        return <Loader/>;
    }
    if (window.location.pathname === '/callback2') {
        loginWithRedirect();
        return <Loader/>;
    }
    return <>{children}</>;
}