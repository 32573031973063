export enum LogLevel {
	TRACE = 1000,
	DEBUG = 2000,
	INFO = 3000,
	WARN = 4000,
	ERROR = 5000,
	FATAL = 6000
};

// When running locally, get settings from the .env file
// In production, these settings are injected into the index.html file by neon-app-server
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
	(window as any)._env = process.env;
}

const get = (name: string) => {
	const value = (window as any)._env[name];
	if (!value) throw new Error('Environment variable ' +  name + ' does not exist');
	return value;
};

const getSilently = (name: string) => {
	let value;
	try {
		value = get(name);
	} catch (_) {}
	return value;
}

export const config = {
	env: getSilently('NODE_ENV') || '',
	auth: {
		clientId: get('REACT_APP_AUTH_CLIENT_ID'),
		domain: get('REACT_APP_AUTH_DOMAIN'),
		audience: get('REACT_APP_AUTH_AUDIENCE'),
		redirectUri: get('REACT_APP_BASE_URL') + '/callback',
		returnToUri: get('REACT_APP_BASE_URL') + '/loggedout'
	},
	oneTrust: {
		enableLogin: !!getSilently('REACT_APP_SHOW_ONETRUST_LOGIN') && getSilently('REACT_APP_SHOW_ONETRUST_LOGIN') !== 'false',
	},
	recaptchaKey: getSilently('REACT_APP_RECAPTCHA_CLIENT_ID'),
	dashboardServer: get('REACT_APP_DASHBOARD_SERVER'),
	onboardingServer: get('REACT_APP_ONBOARDING_SERVER'),
	baseUrl: get('REACT_APP_BASE_URL'),
	energyUrl: get('REACT_APP_ENERGY_URL'),
	crudeUrl: get('REACT_APP_CRUDE_URL'),
	carbonUrl: get('REACT_APP_CARBON_URL'),
	preciousUrl: get('REACT_APP_PRECIOUS_URL'),
	metalsUrl: get('REACT_APP_METALS_URL'),
	fxUrl: get('REACT_APP_FX_URL'),
	scrapUrl: get('REACT_APP_SCRAP_URL'),
	cscUrl: get('REACT_APP_CSC_URL'),
	traderUrl: get('REACT_APP_TRADER_URL'),
	passportUrl: get('REACT_APP_PASSPORT_URL'),
	insightsUrl: get('REACT_APP_INSIGHTS_URL'),
	portfolioUrl: get('REACT_APP_PORTFOLIO_URL'),
	neonTraderUkUrl: get('REACT_APP_NEONTRADER_UK_URL'),
	neonTraderUkVersion: getSilently('REACT_APP_NEONTRADER_UK_VERSION'),
	neonTraderUsUrl: get('REACT_APP_NEONTRADER_US_URL'),
	neonTraderUsVersion: getSilently('REACT_APP_NEONTRADER_US_VERSION'),
	neonTraderUsBetaUrl: get('REACT_APP_NEONTRADER_US_BETA_URL'),
	neonTraderShanghaiUrl: get('REACT_APP_NEONTRADER_SHANGHAI_URL'),
	neonTraderShanghaiVersion: getSilently('REACT_APP_NEONTRADER_SHANGHAI_VERSION'),
	neonTraderUsBetaVersion: getSilently('REACT_APP_NEONTRADER_US_BETA_VERSION'),
	neonTraderUkTrainerUrl: get('REACT_APP_NEONTRADER_UK_TRAINER_URL'),
	neonTraderUkTrainerVersion: getSilently('REACT_APP_NEONTRADER_UK_TRAINER_VERSION'),
	neonRiskUrl: get('REACT_APP_NEONRISK_URL'),
	nominationsUrl: get('REACT_APP_NOMINATIONS_URL'),
	recsUrl: get('REACT_APP_RECS_URL'),
	onboardingUrl: get('REACT_APP_ONBOARDING_URL'),
	conciergeUrl: get('REACT_APP_CONCIERGE_URL'),
	conciergeServiceUrl: get('REACT_APP_CONCIERGE_SERVICE_URL'),
	neonRiskVersion: getSilently('REACT_APP_NEONRISK_VERSION'),
	supportEmail: get('REACT_APP_SUPPORT_EMAIL'),
	applicationSupportEmail: get('REACT_APP_APP_SUPPORT_EMAIL'),
	isSignUpEnabled: !!getSilently('REACT_APP_IS_SIGNUP_ENABLED') && getSilently('REACT_APP_IS_SIGNUP_ENABLED') !== 'false',
	hidePassportTile: JSON.parse(getSilently('REACT_APP_HIDE_PASSPORT_TILE') || false),
	hideOldTraderTile: JSON.parse(getSilently('REACT_APP_HIDE_OLD_TRADER_TILE') || false),
	hideRiskTile: JSON.parse(getSilently('REACT_APP_HIDE_RISK_TILE') || false),
	accountsQueryLimit: getSilently('REACT_APP_ACCOUNTS_QUERY_LIMIT') || 12000,
	oneTrustPolicyBaseUrl: getSilently('REACT_APP_ONETRUST_POLICY_BASE_URL'),
	oneTrustPrivacyPolicyId: getSilently('REACT_APP_ONETRUST_PRIVACY_POLICY_ID'),
	oneTrustCookiePolicyId: getSilently('REACT_APP_ONETRUST_COOKIE_POLICY_ID'),
	oneTrustNeonTermsOfUseId: getSilently('REACT_APP_ONETRUST_NEON_TERMS_OF_USE_ID'),
	oneTrustNeonTermsOfServiceId: getSilently('REACT_APP_ONETRUST_NEON_TERMS_OF_SERVICE_ID'),
	oneTrustNeonPremiumContentTermsOfServiceId: getSilently('REACT_APP_ONETRUST_NEON_PREMIUM_CONTENT_TERMS_OF_SERVICE_ID'),
	googleTagManagerCertificate: getSilently('REACT_APP_GTM_CERTIFICATE'),
	logging: {
		level: LogLevel.INFO,
		url: `/clientlog`,
		batchTimeout: 2000,
		batchSize: 10,
		maxBatchSize: 100
	},
	sendLogs: getSilently('SEND_LOGS')
		? getSilently('SEND_LOGS') === 'true'
		: process.env.NODE_ENV !== 'development', // false only on local
};
