import React from 'react';
import { useContext, createContext } from "react";
import { setSessionData, SessionData, info } from "../helpers/logger";
import * as packageJson from '../../package.json';
import { useSessionId, SessionIdProvider } from 'neon-utils';

const SessionDataContext = createContext<SessionData>({} as any);
export const useSessionData = () => useContext(SessionDataContext);

const SessionDataContextWrapper = ({children}: { children: React.ReactNode }) => {
  const sessionId = useSessionId();

  const sessionData = {
    sessionId,
    appName: packageJson.name,
    appVersion: packageJson.version
  }

  setSessionData(sessionData);

  return (
    <SessionDataContext.Provider value={sessionData}>
      {children}
    </SessionDataContext.Provider>
  );
};

export const SessionDataProvider = ({children}: { children: React.ReactNode }) => {
  return (
    <SessionIdProvider log={info}>
      <SessionDataContextWrapper>
        {children}
      </SessionDataContextWrapper>
    </SessionIdProvider>
  );
};