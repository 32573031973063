export const userInfoFields = `
    neonUserId,
    firstName,
    lastName,
    company,
    phoneNumber,
    country,
    categories,
    acceptedTerms,
    acceptedPrivacy,
    auth0email,
    interest {
        application
        requested
    },
    registrationSurvey {
        clientStatus
        marexContact
    }
`;
