import gql from 'graphql-tag';
import { MyUser } from '../queries/myUser';

export const UPDATE_MY_USER = gql`
	mutation UpdateMyUser($input: MyUserInput!) {
		createOrUpdateMyUser(input: $input) {
			neonUserId
			firstName
			lastName
			company
			phoneNumber
			country
			categories
			acceptedTerms
			acceptedPrivacy
			auth0email
			registrationSurvey {
				clientStatus
				marexContact
			}
		}
	}
`;

export interface UpdateMyUserRequest {
	input: Partial<MyUser>;
}

export interface UpdateMyUserResult {
	createOrUpdateMyUser: MyUser;
}

export default UPDATE_MY_USER;