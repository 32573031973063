import React from "react";
import { makeStyles } from "@material-ui/styles";
import { ExtendedTheme } from "neon-design-system";

interface TemplateProps {
  content: JSX.Element;
  footer?: JSX.Element;
  sideImage: string;
  breakPointStartOnMD?: boolean;
}

const useStyles = makeStyles((theme: ExtendedTheme, ) => {
  return {
    layoutWrapper: {
      display: 'flex',
    },
    sideImage: (props: TemplateProps) => ({
      display: 'none',
      [theme.breakpoints.up(props.breakPointStartOnMD ? 'md' : 'lg')]: {
        display: 'flex',
        position: 'relative',
        'flex-basis': theme.spacing(62.5),
        transition: 'flex-basis 300ms ease-out',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      [theme.breakpoints.up('xl')]: {
        'flex-basis': theme.spacing(75),
      },
    }),
    mainWrapper: {
      display: 'flex',
      flexGrow: 1,
      minHeight: '100vh',
      flexDirection: 'column',
      padding: theme.spacing(4,2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8, 2),
      },
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.down('md')]: {
        flex: 'auto',
      },
    },
    placeholder: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexGrow: 1,
    },
  };
});

const Template = (props: TemplateProps) => {
  const { content, footer, sideImage } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.layoutWrapper}>
    <div
      className={classes.sideImage}
      style={{
        backgroundImage: `url(${sideImage})`
      }}
    />
    <div className={classes.mainWrapper}>
      <div className={classes.content}>
        <div className={classes.placeholder}>{content}</div>
      </div>
      {footer}
    </div>
  </div>
  );
};

export default Template;
