import * as EmailValidator from 'email-validator';

export const validateEmail = (email: string) => EmailValidator.validate(email);

/**
 * Decamelizes a string with/without a custom separator (underscore by default).
 *
 * @param str String in camelcase
 * @param separator Separator for the new decamelized string.
 */
export const decamelize = (str: string, separator?: string) => {
  separator = typeof separator === 'undefined' ? '_' : separator;

  return str
  .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
  .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
  .toLowerCase();
};

export const getUrlParams = (search: string, key: string) => {
  const params = new URLSearchParams(search);

  return params.get(key)
}

export const prepareClassnameList = (classes: (string | undefined)[]) => classes.filter(Boolean).join(' ');


export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}