import React from 'react';
import Template from "../Template/Template";
import sideImage from '../component-library/Images/jpg/trader.jpg';
import DownloadAppContent from './DownloadAppContent';
import DownloadAppItem from './DownloadAppItem';
import SimpleFooter from "../component-library/Navigation/Footer/SimpleFooter";
import { createStyles, makeStyles } from "@material-ui/core";
import { ExtendedTheme, Logomark } from "neon-design-system";
import { useAuth0Data } from "neon-auth0";
import { config } from "../../config";
import { userDownloadScopes } from "../../helpers/auth0";

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
  footer: {
    textAlign: 'center',
    maxWidth: 'initial',
  },
  logo: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  downloadItemsSmallWrapper: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div:not(:last-child)': {
      marginRight: theme.spacing(2),
    }
  },
  downloadItemsBigWrapper4: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',

    '& > div': {
      flex: '0 47%',
      margin: theme.spacing(1),
    },
  },
  downloadItemsBigWrapper5: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    '& > div': {
      flex: '0 30%',
      margin: theme.spacing(1),
    },
  }
}));

export const TraderLayout = () => {
  const classes = useStyles();
  const { scopes } = useAuth0Data();
  const numberOfDownload = userDownloadScopes(scopes).length;

  return (
      <Template
        content={
          <DownloadAppContent
            logo={<Logomark app="Trader" className={classes.logo} />}
            logoTitle="Trader"
            title="Download Neon Trader"
            content={
              <div className={numberOfDownload > 3 ? (numberOfDownload === 4  ? classes.downloadItemsBigWrapper4 : classes.downloadItemsBigWrapper5) : classes.downloadItemsSmallWrapper}>
                {
                  scopes.includes('connect') && scopes.includes('connect:download-uk') && (
                    <DownloadAppItem
                      name="Trader UK"
                      version={config.neonTraderUkVersion}
                      link={config.neonTraderUkUrl}
                    />
                  )
                }
                {
                  scopes.includes('connect') && scopes.includes('connect:download-us') && (
                    <DownloadAppItem
                      name="Trader US"
                      version={config.neonTraderUsVersion}
                      link={config.neonTraderUsUrl}
                    />
                  )
                }
                {
                  scopes.includes('connect') && scopes.includes('connect:download-us-beta') && (
                    <DownloadAppItem
                      name="Trader US Beta"
                      isBeta
                      version={config.neonTraderUsBetaVersion}
                      link={config.neonTraderUsBetaUrl}
                    />
                  )
                }
                {
                  scopes.includes('connect') && scopes.includes('connect:download-shanghai') && (
                    <DownloadAppItem
                      name="Trader Shanghai"
                      version={config.neonTraderShanghaiVersion}
                      link={config.neonTraderShanghaiUrl}
                    />
                  )
                }
                {
                  scopes.includes('connect') && scopes.includes('connect:download-uk-trainer') && (
                    <DownloadAppItem
                      name="Trainer"
                      version={config.neonTraderUkTrainerVersion}
                      link={config.neonTraderUkTrainerUrl}
                    />
                  )
                }
              </div>
            }
            description="Real-time exchange trading with access to global futures and options markets."
            versionDescription="Neon Trader requires Windows 10 and Microsoft .NET Framework 4.6 (x86 and x64)"
          />
        }
        footer={<SimpleFooter contentClassName={classes.footer} />}
        sideImage={sideImage}
      />
    );
};

export default TraderLayout;