import React, { useEffect, Fragment } from 'react';
import { useAuth0 } from "neon-auth0";

export const Login = () => {
    const auth0Data = useAuth0();

    useEffect(() => {
       auth0Data.loginWithRedirect();
    }, []);

    return (<Fragment />);
};

export default Login;