import { useEffect, useState } from "react";
import { WebAuth } from "auth0-js";
import { config } from "../../config";

export const useWebAuth = () => {
    const [authClient, setAuthClient] = useState<WebAuth | undefined>(undefined);
 
    useEffect(() => {
        setAuthClient(new WebAuth({
            domain: config.auth.domain,
            clientID: config.auth.clientId,
        }));
    }, []);

    return authClient;
}