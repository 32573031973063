export function getSetReturnUrl() {
    const returnTo = getReturnUrlFromLocation();
    if (returnTo) {
        window.sessionStorage.setItem('returnTo', returnTo);
    }
    return returnTo;
}

export function getReturnUrlFromLocation() {
    return (new URLSearchParams(window.location.search)).get('redirect'); // url of the application that sent us to login / complete page
}

export function clearReturnUrlFromStorage() {
    window.sessionStorage.removeItem('returnTo');
}

export function getReturnUrl() {
    return window.sessionStorage.getItem('returnTo');
}

export function getReturnUrlFromIdToken(idToken: any) {
    const metadata = idToken[`${idToken.iss}user_metadata`];
    if(metadata) {
      return metadata.returnUrl;
    }
    return null;
}
