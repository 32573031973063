import { Option } from 'neon-design-system';
import countriesJSON from './countries.json';
export interface Country {
    name: string;
    'alpha-2': string;
    'alpha-3': string;
    'country-code': string;
    'iso_3166-2': string;
    region: string;
    'sub-region': string;
    'intermediate-region': string;
    'region-code': string;
    'sub-region-code': string;
    'intermediate-region-code': string;
}

export const countries: Country[] = countriesJSON;
export const countriesOptions: Option[] = countries.map(c => ({
    label: c.name,
    value: c['alpha-2']
}));

export const sanctionedCountries = ['CU', 'IR', 'KP', 'SY', 'RU'];
export const isSanctioned = (country: string) => sanctionedCountries.indexOf(country) > -1