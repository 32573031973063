import React, { useEffect, lazy, Suspense, useCallback } from 'react';
import { useAuth0, useAuth0Data } from 'neon-auth0';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import packageJson from "../../package.json";
import { CssBaseline, makeStyles } from '@material-ui/core';
import Footer from './component-library/Navigation/Footer/Footer';
import {
	AppBar,
	TextBlock,
	Button,
	UserDetails,
	UserInterfaceUserCircle,
	TextLabelStyles,
	ExtendedTheme,
	ArrowsArrowUp,
	UserInterfaceConversation
} from 'neon-design-system'
import { baseUrls } from "../helpers/urls";
import ManageAccount from '../pages/Account/ManageAccount';
import RegistrationComplete from '../pages/RegistrationComplete';
import { getStaticRoutes } from '../helpers/staticRoutes';
import DownloadTrader from "./DownloadAppLayout/DownloadTraderLayout";
import DownloadRisk from "./DownloadAppLayout/DownloadRiskLayout";
import { isNeonTraderDownloadEnabled } from "../helpers/auth0";
import { useSessionData } from '../contexts/SessionDataProvider';
import * as log from '../helpers/logger';
import * as appcues from 'neon-appcues';
import { useQuery } from "@apollo/client";
import GET_MY_USER, { MyUserResponseWithPackageInfo, Tiers } from "../graphQL/queries/myUser";
import { config } from '../config';

const Dashboard = lazy(() => import('./Dashboard'));
const EmailVerification = lazy(() => import('./EmailVerification'));
const Error = lazy(() => import('./Error'));
const { version } = packageJson;

const useStyles = makeStyles((theme: ExtendedTheme) => {
	return ({
		loading: {
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0
		},
		logout: {
			position: 'absolute',
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'center',
			top: 0,
			bottom: theme.spacing(8),
			left: 0,
			right: 0
		},
		root: {
			display: 'flex',
			overflowX: 'hidden'
		},
		Site: {
			display: "flex",
			flexGrow: 1,
			flexDirection: "column",
			minHeight: "100vh",
		},
		SiteContent: {
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			width: "100%"
		},
		package: {
			backgroundColor: theme.themeColors.secondary.dark,
		},
		upgradeMenuItem: {
			fontWeight: 700,
			color: theme.palette.common.white,
			background: theme.palette.secondary.dark,
			'&:hover': {
				background: theme.palette.secondary.dark,
			}
		}
	});
});

const App: React.FC = () => {
	const classes = useStyles();
	const { isAuthenticated, isLoading, logout, user, getAccessTokenSilently } = useAuth0();
	const { scopes, idToken, accessToken } = useAuth0Data();
	const history = useHistory();
	const sessionData = useSessionData();
	const { data } = useQuery<{ myUser: MyUserResponseWithPackageInfo }>(GET_MY_USER, { fetchPolicy: 'network-only' });

	const footerDisabledRoutes = [baseUrls.neonTraderDownload, baseUrls.neonRiskDownload];
	const isFooterEnabled = !footerDisabledRoutes.some(route => history.location.pathname.includes(route));
	const navBarItems = [
		{ name: 'Dashboard', link: baseUrls.dashboard },
		{ name: 'Support Center', link: config.conciergeUrl, ignoreRouter: true },
		{ name: 'Account settings', link: baseUrls.manageAccount }
	];

	useEffect(() => {
		const setupAppcues = () => {
			appcues.init({
				appName: 'dashboard',
				accessToken: accessToken,
				getAccessToken: getAccessTokenSilently,
				idToken: idToken.__raw,
				log(message, data) {
					log.info('Appcues dashboard', data);
				},
				analyticOptions: {
					env: appcues.environmentResolver(config.env),
					recordAnalytics: config.sendLogs,
					analyticsOnly: false,
					sessionData: {
					sessionId: sessionData.sessionId,
					appName: 'Neon Dashboard',
					appVersion: packageJson.version
				}}
			});

			appcues.identify({});
			appcues.analyticsLog({});

			history.listen(appcues.page);
			history.listen((_, __) => appcues.analyticsLog({}));			
		};

		if (isAuthenticated && idToken) {
			setupAppcues();
		}
	}, [isAuthenticated, idToken, accessToken, getAccessTokenSilently, history]);

	const formatUserLabel = useCallback(() => {
		if (!data?.myUser)
			return undefined;

		if (data.myUser.broker) {
			return {
				label: "BROKER",
				styleName: 'primary' as TextLabelStyles,
			}
		}
		if (data.myUser.isInternal) {
			return {
				label: "INTERNAL USER",
				styleName: 'primary' as TextLabelStyles,
			}
		}
		if (data.myUser.isBasic) {
			return {
				label: "BASIC PACKAGE",
				styleName: 'secondary' as TextLabelStyles,
				className: classes.package,
			}
		}
		if (data.myUser.tier === Tiers.STANDARD) {
			return {
				label: "STANDARD PACKAGE",
				styleName: 'secondary' as TextLabelStyles,
				className: classes.package,
			}
		}
	}, [data]);
	
	const isUpgradeToStandardShown = data &&
		data.myUser.isBasic && !data.myUser.isInternal && !data.myUser.broker;

	const upgradeToStandard = isUpgradeToStandardShown ? [{
		name: 'Upgrade to Standard',
		icon: <ArrowsArrowUp />,
		onClick: () => history.push(`${baseUrls.manageAccount}/package`),
		className: classes.upgradeMenuItem,
	}] : [];

	const Loading = () =>
		<>
			<TextBlock className={classes.loading} styleName="footnote">Loading</TextBlock>
			<div className={classes.logout}>
				<Button colorType="grey" onClick={() => logout({returnTo: window.location.origin})}>Log out</Button>
			</div>
		</>

	const userDetails : UserDetails = {
		name: (user && user.given_name && user.family_name && (user.given_name + ' ' + user.family_name)) ||
				(user && user.name) ||
				(user && user.email) ||
				'',
		role: '',
		sessionId: sessionData.sessionId,
		userLabel: formatUserLabel(),
	};

	const decorateWithAppBar = (child: JSX.Element) => <>
		{isAuthenticated && <AppBar
			onSignOut={() => logout({returnTo: window.location.origin})}
			appVersion={version}
			homePageRoute={baseUrls.dashboard}
			userDetails={userDetails}
			showAppSwitcher
			menuItems={navBarItems}
			accountMenuItems={[
				...upgradeToStandard,
				{
					name: 'Manage account',
					icon: <UserInterfaceUserCircle/>,
					onClick: () => history.push(baseUrls.manageAccount)
				},
				{
					name: 'Support Center',
					icon: <UserInterfaceConversation />,
					onClick: () => {
						window.location.href = config.conciergeUrl
					}
				}
			]}
		/>}
		{child}
	</>;

	return (
		<div className={classes.root}>
			<div className={classes.Site}>
				<div className={classes.SiteContent}>
				<CssBaseline />
				<Suspense fallback={<Loading />}>
					{isLoading ?
						<Loading /> :
						<>
							<Switch>
								<Route exact path={baseUrls.dashboard} render={() => decorateWithAppBar(<Dashboard />)} />
								<Route path={baseUrls.manageAccount} render={() => decorateWithAppBar(<ManageAccount />)}/>
								<Route path={baseUrls.emailverification} component={EmailVerification} />
								<Route path={baseUrls.error} component={Error} />
								<Route path={baseUrls.complete} component={RegistrationComplete} />
								{getStaticRoutes()}
								{
									isNeonTraderDownloadEnabled(scopes) && (
										<Route path={baseUrls.neonTraderDownload} component={DownloadTrader} />
									)
								}
								{
									scopes.includes('connect:download-uk-risk') && (
										<Route path={baseUrls.neonRiskDownload} component={DownloadRisk} />
									)
								}
								<Redirect to={baseUrls.dashboard}/>
							</Switch>
						</>
					}
				</Suspense>
				</div>
				{isFooterEnabled && <Footer />}
			</div>
		</div>
	);
}

export default App;
