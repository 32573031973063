import gql from 'graphql-tag';
import { MyUserQueryResult } from './myUser';
import { CategoriesQueryResult } from './categories';
import { userInfoFields } from './partials';

export const GET_MY_USER_WITH_CATEGORIES = gql`
	query myUserWithCategories{
		myUser {
			${userInfoFields}
		}
		categories {
			name
		}
	}
`;

export interface MyUserWithCategoriesQueryResult extends MyUserQueryResult, CategoriesQueryResult {}

export default GET_MY_USER_WITH_CATEGORIES;