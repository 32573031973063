import React from 'react';
import { ExtendedTheme, MarexSpectronLogo } from 'neon-design-system';
import { makeStyles, createStyles  } from '@material-ui/styles';
import CopyrightText from './CopyrightText';

interface SimpleFooterProps {
    className?: string;
    contentClassName?: string;
}

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
    footer: {
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        maxWidth: theme.spacing(50),
    },
    logo: {
        marginTop: theme.spacing(1.5),
        width: '100px',
        height: 'initial',
    }
}));

const SimpleFooter = (props: SimpleFooterProps) => {
    const classes = useStyles();
    const { className, contentClassName } = props;

    return (
        <div className={[classes.footer, className].join(' ')}>
            <div className={[classes.content, contentClassName].join(' ')}>
                <MarexSpectronLogo className={classes.logo} />
                <CopyrightText />
            </div>
        </div>
    );
};

export default SimpleFooter;
