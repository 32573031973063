import React, { useEffect } from 'react';
import { ExtendedTheme } from 'neon-design-system';
import { makeStyles } from '@material-ui/core';
import { config } from '../../config';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
	oneTrustPolicyWrapper: {
		'& .otnotice-content': {
			padding: 0,
			'& .otnotice-sections': {
				'& .otnotice-section:first-of-type h2': {
					fontSize: '36px',
					fontStyle: 'normal',
					fontWeight: 600,
					lineHeight: '48px',
					letterSpacing: '-0.01em',
				},
				'& h2': {
					fontSize: '18px',
					fontStyle: 'normal',
					fontWeight: 'bold',
					lineHeight: '24px',
				},
				'& .otnotice-section-header': {
					margin: theme.spacing(4, 0, 0, 0)
				},
				'& .otnotice-section-content > p, & .otnotice-section-content li': {
					margin: theme.spacing(1, 0),
					fontSize: '14px',
					lineHeight: '20px',
				},
				'& a': {
					textDecoration: 'none',
					color: theme.themeColors.secondary[500],
					fontSize: '14px',
					'&:hover': {
						color: theme.themeColors.secondary[700],
					},
				},
				'& table': {
					borderCollapse: 'collapse',
					borderSpacing: 0,
					margin: theme.spacing(1, 0),
					fontSize: '14px',
					lineHeight: '20px',
					'& td, & tr': {
						padding: theme.spacing(0.5)
					},
				},
			},
		},
	},
}));

export const OneTrustPolicyWrapper = ({ policyId, onPolicyLoad }: { policyId: string; onPolicyLoad?: () => void }) => {
	const classes = useStyles();

	useEffect(() => {
		window.OneTrust.NoticeApi.Initialized.then(() => {
			window.OneTrust.NoticeApi.LoadNotices([`${config.oneTrustPolicyBaseUrl}${policyId}.json`], false).then(() => {
				onPolicyLoad?.();
			});
		});
	}, []);

	return (
		<div className={classes.oneTrustPolicyWrapper}>
			<div id={`otnotice-${policyId}`} className='otnotice'></div>
		</div>
	);
};
