import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import GET_MY_USER, { MyUserQueryResult } from '../graphQL/queries/myUser';
import { Loader } from 'neon-design-system';
import RegistrationComplete from '../pages/RegistrationComplete';
import { setNeonUserId } from '../helpers/logger';
import { Redirect, Route, Switch } from "react-router-dom";
import { baseUrls } from "../helpers/urls";
import { getStaticRoutes } from "../helpers/staticRoutes";
import Error from './Error';
import { isSanctioned } from '../helpers/countries';
import { Sanctioned } from './Sanctioned';
import SplashScreenImage from './component-library/Images/png/Landing.png';
import Template from './Template/Template';

export const CheckCompleteProfile = ({children}: {children: React.ReactNode}) => {
    const [ completeProfile, setComplete ] = useState<boolean | undefined>(undefined);
    const [ sanctioned, setSanctioned ] = useState(false);
    const { data, error } = useQuery<MyUserQueryResult>(GET_MY_USER, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if(data && data.myUser) {
            setNeonUserId(data.myUser.neonUserId);
            setComplete(!!data.myUser.firstName);
            data.myUser.country && setSanctioned(isSanctioned(data.myUser.country));
        }
    }, [data]);

    if(error) {
        return <Error />;
    }

    if(completeProfile === undefined) return <Loader />;
    if(sanctioned) return <Template content={<Sanctioned/>} sideImage={SplashScreenImage} />;
    if(completeProfile) return <>{children}</>;

    return (
      <Switch>
          {getStaticRoutes(true)}
          <Route path={baseUrls.complete} component={() => <RegistrationComplete continueRegistration />} />
          <Redirect to={baseUrls.complete} />
      </Switch>
    );
};

export default CheckCompleteProfile;