import React from 'react';

import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { errorlink, cleanTypenameLink, httpLink } from './ApolloLinks';

// import gql from 'graphql-tag';
// import { loader } from 'graphql.macro';
import { useAuth0 } from 'neon-auth0';
import { useSessionData } from "../contexts/SessionDataProvider";

let callId = 0;  // sequential call id that is used for the server calls

// const cellValue = loader('../../graphQL/typedefs/cellValue.graphql');

// https://www.apollographql.com/docs/react/get-started/
// https://www.apollographql.com/docs/tutorial/queries/
// caching: https://www.apollographql.com/docs/react/data/queries/
// https://www.apollographql.com/docs/react/data/mutations/


const ApolloAuth0Client = (props: any) => {
	// The Auth0 GraphQL link to authenticate and to place the Auth0 token into each call
	// needs to be part of a component as it relies on the useAuth0 Hook
	// See:
	// https://www.apollographql.com/docs/react/networking/authentication/
	const { getAccessTokenSilently } = useAuth0();
	const { sessionId, appName, appVersion } = useSessionData();

	const authLink = setContext(async (_, { headers }) => {
		const accessToken = await getAccessTokenSilently();

		// bump the call id
		callId += 1;

		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: `Bearer ${accessToken}`,
				callid: callId,
				sessionid: sessionId,
			}
		}
	});

	const cache = new InMemoryCache();

	// Helper function to get data from the cache
    // const getState = (query: any): any => {
    //     return cache.readQuery<any>({ query }).state;
    // };

    // Helper function to write data back to the cache
  //   const writeState = (state: any) => {
  //       return cache.writeData({ data: { state } });
	// };

	// Use local GraphQL state instead of redux:
	// https://www.apollographql.com/docs/react/data/local-state/
	// https://medium.com/the-notice-board/life-without-redux-using-apollo-for-local-state-d32b020ff4d3
	// https://itnext.io/managing-local-state-with-apollo-client-and-react-hooks-9ad357e6d649

	const client = new ApolloClient({
		link: ApolloLink.from([errorlink, authLink, cleanTypenameLink, httpLink]),
		cache: cache,
		name: appName,
		version: appVersion,
	});

	return (<ApolloProvider client={client}>
			{ props.children }
			</ApolloProvider>);
}

export default ApolloAuth0Client;
