import React from 'react';
import { Route } from 'react-router-dom';
import { baseUrls } from './urls';
import { TermsOfService } from '../pages/Policies/TermsOfService';
import { TermsOfServicePremiumContent } from '../pages/Policies/TermsOfServicePremiumContent';
import { Privacy } from '../pages/Policies/Privacy';
import { TermsOfUse } from '../pages/Policies/TermsOfUse';
import { CookiePolicy } from '../pages/Policies/CookiePolicy';
import StaticLayout from '../components/StaticLayout/StaticLayout';

const withStaticLayout = (withFooter: boolean) => (element: JSX.Element) => {
    return (
        <StaticLayout showFooter={withFooter}>
            {element}
        </StaticLayout>
    );
}

export const getStaticRoutes = (withFooter: boolean = false) => {
    const render = withStaticLayout(withFooter);
    return [
        <Route key={'termsOfService'} path={baseUrls.termsOfService} exact={true} render={() => render(<TermsOfService />)}/>,
        <Route key={'termsOfServicePremiumContent'} path={baseUrls.termsOfServiceForPremiumContent} exact={true} render={() => render(<TermsOfServicePremiumContent />)}/>,
        <Route key={'privacy'} path={baseUrls.privacy} exact={true} render={() => render(<Privacy />)}/>,
        <Route key={'termsOfUse'} path={baseUrls.termsOfUse} exact={true} render={() => render(<TermsOfUse />)}/>,
        <Route key={'cookie-policy'} path={baseUrls.cookiePolicy} exact={true} render={() => render(<CookiePolicy />)}/>,
    ];
}